import { SHOW_MESSAGE } from "../actions/messageAction"

const initialState = {
    message: "",
    type: "info"
}

const messageReducer = (state = initialState, action) => {

    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                type: action.payload.type
            }

        default: 
            return state;
    }
}

export default messageReducer;