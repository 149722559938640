import React from 'react';
import { Spin } from 'antd';
import "./Loader.css";
import { useSelector } from 'react-redux';
const Loader = () => {

    const theme = useSelector((state) => state.theme).theme;

    return (
        <div className={`${theme === "dark" && 'dark'}`}>
            <Spin
                className='flex items-center justify-center sticky top-0 bg-gray-100 dark:bg-dark'
                style={{ zIndex: 99999, width: "100vw", height: "100vh", overflow: "hidden" }}
                size="large"
            />
        </div>
    );
};

export default Loader;