export const NotificationType = Object.freeze({
  ShareAssignment: "ShareAssignment",
  ShareQuiz: "ShareQuiz",
  SharePost: "SharePost",
  ShareSurvey: "ShareSurvey",
  AssignmentGiveNote: "AssignmentGiveNote",
  QuizGiveNote: "QuizGiveNote",
  Invite: "Invite",
  CreateObjection: "CreateObjection",
  AnswerQuizObjection: "AnswerQuizObjection",
  AnswerAssignmentObjection: "AnswerAssignmentObjection",
  RemovedFromLesson: "RemovedFromLesson",
  JoinRequest: "JoinRequest",
});
