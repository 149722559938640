import React from 'react'

const AnimatedGradientText = ({ text }) => {
    return (
        <span className='gradient-text' style={{
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text",
        }}>{text}</span>
    )
}

export default AnimatedGradientText