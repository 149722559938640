import { combineReducers } from "redux";
import messageReducer from "./messageReducer";
import notificationReducer from "./notificationReducer";
import pageReducer from "./pageReducer";
import themeReducer from "./themeReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  message: messageReducer,
  notification: notificationReducer,
  page: pageReducer,
  user: userReducer,
  theme: themeReducer,
});

export default rootReducer;
