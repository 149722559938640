import { Icon } from '@iconify/react';
import { Avatar, Col, Popover, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { utcToLocaleLangFormat } from '../../../helpers/helperFunctions';
import { NotificationType } from '../../../models/enums/NotificationType';
import httpService from '../../../services/httpService';

const NotificationNoteItem = ({ notification }) => {
    const navigate = useNavigate();
    const navigateToContent = () => {
        httpService.put(`notification/readNotification/${notification.id}`).then().catch();
        switch (notification.type) {
            case NotificationType.AssignmentGiveNote:
                return navigate(`/assignment/${notification.contentClassId}/${notification.contentId}`);
            case NotificationType.QuizGiveNote:
                return navigate(`/quizinspect/${notification.contentClassId}/${notification.contentId}`);
            default:
                return;
        }
    };
    return (
        <div onClick={() => navigateToContent()} className='hover:bg-gray-100 dark:hover:bg-dark2 cursor-pointer rounded-md transition-all duration-300 p-2'>
            <Row align={'top'}>
                <Col span={3}>
                    {notification.createdProfilePicture && notification.createdProfilePicture !== ""
                        ?
                        <Avatar size={'large'} src={notification.createdProfilePicture} />
                        :
                        <Avatar size={'large'} className='bg-purple-100 dark:bg-purple-400'><Icon icon="clarity:note-line" className='text-base mt-2.5 text-purple-500 dark:text-gray-100' /></Avatar>
                    }
                </Col>
                <Col span={20}>
                    <div>
                        <Popover className='dark:text-white' content={notification.createdUserName + ", " + notification.contentTitle + "içeriğine not verdi."}>
                            <p className='text-base'>{notification.createdUserName} <span className='text-primary text-sm'>not verdi</span> {notification.contentTitle.length > 15 ? `${notification.contentTitle.slice(0, 15)}...` : notification.contentTitle}</p>
                        </Popover>
                        <div className='flex space-x-2 items-center mt-1'>
                            <p className='text-xs text-gray-400'>{utcToLocaleLangFormat(notification.createdAt)}</p>
                            {/* <Avatar size={4} className='bg-gray-300' />
                            <p className='text-xs text-gray-400'>{notification.contentClassName}</p> */}
                        </div>
                    </div>
                </Col>
                {
                    !notification.isSeen && (
                        <Col span={1}>
                            <Avatar size={6} className='bg-[#5161ce]' />
                        </Col>
                    )
                }
            </Row>
        </div>
    );
};

export default NotificationNoteItem;