import { ConfigProvider, message, notification, theme } from "antd";
import React, { Suspense, lazy, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { getAntdLocale } from "./helpers/helperFunctions";
// import SignalR from "./pages/SignalR";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { changePage } from "./actions/pageAction";
import { changeTheme } from "./actions/themeAction";
import LoadingAnimation from "./components/LoadingAnimation";
import LandingPage from "./pages/LandingPage/LandingPage";
import ProLayout from "./pages/Layout";
import ProLoginPage from "./pages/LoginPage/LoginPage";
import TestAssignment from "./pages/TestAssignment/TestAssignment";
import storageService from "./services/storageService";

const ProProfile = lazy(() => import("./pages/Profile/Profile"));
const ProObjection = lazy(() => import("./pages/Objection/Objection"));
const ProCreateQuizObjection = lazy(() =>
  import("./pages/CreateQuizObjection/CreateQuizObjection")
);
const ProCreateAssignmentObjection = lazy(() =>
  import("./pages/CreateAssignmentObjection/CreateAssignmentObjection")
);
const ProForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ProResetPassword = lazy(() => import("./pages/ResetPassword"));
const ProQuizInspect = lazy(() => import("./pages/QuizInspect/QuizInspect"));
const ProQuizCreate = lazy(() => import("./pages/QuizCreate/QuizCreate"));
const ProNotFound = lazy(() => import("./pages/NotFound"));
const ProError = lazy(() => import("./pages/Error"));
const ProStudents = lazy(() => import("./pages/Students/Students"));
const Quiz = lazy(() => import("./pages/Quiz/Quiz"));
const ProAssignmentDetail = lazy(() =>
  import("./pages/AssignmentDetail/AssignmentDetail")
);
const ProAssignmentNoteList = lazy(() =>
  import("./pages/AssignmentNoteList/AssignmentNoteList")
);
const ProQuizNoteList = lazy(() => import("./pages/QuizNoteList/QuizNoteList"));
const Loader = lazy(() => import("./components/Loader/Loader"));
const ProConfirmUser = lazy(() => import("./pages/ConfirmUser/ConfirmUser"));
const ProClasses = lazy(() => import("./pages/Classes/Classes"));
const ProClass = lazy(() => import("./pages/Class/Class"));
const ClassFiles = lazy(() => import("./pages/ClassFiles/ClassFiles"));

function App() {
  const [messageApi, messageHolder] = message.useMessage();
  const [api, notificationHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const messageState = useSelector((state) => state.message);
  const notificationState = useSelector((state) => state.notification);

  const { t, i18n } = useTranslation();

  const currentTheme = useSelector((state) => state.theme).theme;

  useEffect(() => {
    const theme = storageService.getCurrentTheme();
    dispatch(changeTheme(theme));
  }, []);

  useEffect(() => {
    dispatch(changePage(storageService.getCurrentPage()));
  }, []);

  useEffect(() => {
    if (messageState.message !== "") {
      messageApi.open({
        type: messageState.type,
        content: messageState.message,
      });
    }
  }, [messageState]);

  useEffect(() => {
    if (
      notificationState.type !== undefined &&
      notificationState.message !== ""
    ) {
      api[notificationState.type]({
        message: notificationState.message,
        description: notificationState.description,
        placement: "bottomRight",
      });
    }
  }, [notificationState]);

  return (
    <GoogleOAuthProvider clientId="301060816207-r1r1ev4t78vtk83vr162j3hjcopl3mdv.apps.googleusercontent.com">
      {messageHolder}
      {notificationHolder}
      <ConfigProvider
        theme={{
          algorithm:
            currentTheme === "dark"
              ? theme.darkAlgorithm
              : theme.defaultAlgorithm,

          components: {
            Menu: {
              itemSelectedBg: currentTheme === "dark" ? "#5161ce" : "#5161ce30",
              itemSelectedColor: currentTheme === "dark" ? "white" : "#5161ce",
            },
          },
          token: {
            colorPrimary: "#5161ce",
            colorBgBase: currentTheme === "dark" ? "#161725" : "white",
            colorBgContainer: currentTheme === "dark" ? "#2f3249" : "white",
          },
        }}
        locale={getAntdLocale(i18n.language)}
      >
        <ErrorBoundary FallbackComponent={Error}>
          <Suspense fallback={<LoadingAnimation />}>
            <Router>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/quiz/:classId/:quizId" element={<Quiz />} />
                <Route path="/login" element={<ProLoginPage />} />
                {/* <Route path="/signalr" element={<SignalR />} /> */}
                <Route path="/" element={<ProLayout theme={currentTheme} />}>
                  <Route path="/classes" element={<ProClasses />} />
                  <Route path="/classes/:classId" element={<ProClass />} />
                  <Route path="/students/:classId" element={<ProStudents />} />
                  <Route
                    path="/class-files/:classId"
                    element={<ClassFiles />}
                  />
                  <Route
                    path="/assignment/:classId/:assignmentId"
                    element={<ProAssignmentDetail />}
                  />
                  <Route
                    path="/testassignment/:classId/:assignmentId"
                    element={<TestAssignment />}
                  />
                  <Route
                    path="/notelist/:classId/:assignmentId"
                    element={<ProAssignmentNoteList />}
                  />
                  <Route
                    path="/quiznotelist/:classId/:quizId"
                    element={<ProQuizNoteList />}
                  />
                  <Route
                    path="/quizcreate/:classId/:quizId"
                    element={<ProQuizCreate />}
                  />
                  <Route
                    path="/quizinspect/:classId/:quizId"
                    element={<ProQuizInspect />}
                  />
                  <Route
                    path="/quizinspect/:classId/:quizId/:studentId"
                    element={<ProQuizInspect />}
                  />
                  <Route path="/profile" element={<ProProfile />} />
                  <Route
                    path="/objection/:classId"
                    element={<ProObjection />}
                  />
                  <Route
                    path="/objection/:classId/assignment/create/:assignmentId"
                    element={<ProCreateAssignmentObjection />}
                  />
                  <Route
                    path="/objection/:classId/quiz/create/:quizId"
                    element={<ProCreateQuizObjection />}
                  />
                </Route>
                <Route path="/confirm" element={<ProConfirmUser />} />
                <Route path="/forgotpassword" element={<ProForgotPassword />} />
                <Route path="/resetpassword" element={<ProResetPassword />} />
                <Route path="*" element={<ProNotFound />} />
                <Route path="/error" element={<ProError />} />
              </Routes>
            </Router>
          </Suspense>
        </ErrorBoundary>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
