import { Icon } from "@iconify/react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "../../../actions/notificationAction";
import httpService from "../../../services/httpService";
import storageService from "../../../services/storageService";

function CreateClass({ classCreateOpen, setClassCreateOpen }) {
  const [classNameText, setClassNameText] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState("#5161ce");
  const [isProtected, setIsProtected] = useState(false);
  const theme = useSelector((state) => state.theme).theme;

  const colorPalette = [
    "#5161ce",
    "#009879",
    "#e4a618",
    "#c464b4",
    "#34aec6",
    "#bc6433",
  ];

  const dispatch = useDispatch();

  const handleClassCreateOk = () => {
    if (classNameText === "") {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.CREATE_CLASS.EMPTY"),
        })
      );
      return;
    }
    setLoading(true);
    httpService.post(
      "lesson/create",
      {
        className: classNameText,
        isProtected: isProtected,
        color: selectedColor,
      },
      storageService.getToken()
    )
      .then((res) => {
        if (res.data.statusCode === 201) {
          // create class folder
          window.location.reload();
          dispatch(
            showNotification({
              type: "success",
              message: t("NOTIFICATION.CREATE_CLASS.SUCCESS"),
            })
          );
          setClassNameText("");
          setLoading(false);
          setClassCreateOpen(false);
        } else {
          dispatch(
            showNotification({
              type: "error",
              message: t("NOTIFICATION.CREATE_CLASS.ERROR"),
            })
          );
          setClassNameText("");
          setLoading(false);
          setClassCreateOpen(false);
        }
      })
      .catch((e) => {
        dispatch(
          showNotification({
            type: "error",
            message: t("NOTIFICATION.CREATE_CLASS.ERROR"),
          })
        );
        setClassNameText("");
        setLoading(false);
        setClassCreateOpen(false);
      });
  };

  const handleClassCreateCancel = () => {
    setClassNameText("");
    setIsProtected(false);
    setClassCreateOpen(false);
  };

  return (
    <Drawer
      title={t("CLASSES.CREATE_CLASS")}
      open={classCreateOpen}
      width={600}
      className={`${theme === "dark" && "dark"}`}
      onClose={handleClassCreateCancel}
      extra={
        <div>
          <Button
            type="primary"
            onClick={handleClassCreateOk}
            loading={loading}
          >
            {t("CLASSES.CREATE")}
          </Button>
        </div>
      }
    >
      <Row>
        <Col>
          <Icon
            icon="fluent:class-20-filled"
            color="#5161ce"
            className="mt-0.5"
          />
        </Col>
        <Col style={{ marginLeft: "0.5rem" }}>
          <span className="text-gray-900 dark:text-gray-200">{t("CLASSES.CLASS_NAME")}</span>
        </Col>
      </Row>
      <Input
        placeholder={t("CLASSES.CREATE_CLASS_PLACEHOLDER")}
        value={classNameText}
        onChange={(e) => setClassNameText(e.currentTarget.value)}
        maxLength={50}
        style={{ marginTop: "0.5rem" }}
      />

      <Row style={{ marginTop: "1rem" }}>
        <Col>
          <Icon icon="ion:color-fill" color="#5161ce" className="mt-0.5" />
        </Col>
        <Col style={{ marginLeft: "0.5rem" }}>
          <span className="text-gray-900 dark:text-gray-200">{t("CLASSES.COLOR_PALETTE")}</span>
        </Col>
      </Row>
      <div className="flex mt-2">
        <Space>
          {colorPalette.map((item, index) => (
            <Button
              key={index}
              style={{
                backgroundColor: item,
                marginBottom: item === selectedColor && "0.5rem",
              }}
              shape="circle"
              size="small"
              onClick={() => setSelectedColor(item)}
            >
              {selectedColor === item && (
                <Icon
                  icon="mdi:tick"
                  color="white"
                  fontSize={12}

                />
              )}
            </Button>
          ))}
        </Space>
      </div>

      <div className="flex mt-4">
        <Space>
          <Checkbox
            checked={isProtected}
            onChange={(e) => setIsProtected(e.target.checked)}
          />
          <span className="text-gray-900 dark:text-gray-200">
            {t("CLASSES.PROTECTED_CLASS")}
          </span>
          <Tooltip title={t("CLASSES.PROTECTED_CLASS_DESC")}>
            <Icon
              icon="ph:info"
              fontSize={16}
              className="mt-1.5 text-gray-700 dark:text-gray-400"
            />
          </Tooltip>
        </Space>
      </div>
    </Drawer>
  );
}

export default CreateClass;
