export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_IS_TEACHER = "SET_IS_TEACHER";

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  };
};

export const setIsTeacherGlobally = (isTeacher) => {
  return {
    type: SET_IS_TEACHER,
    payload: isTeacher,
  };
};
