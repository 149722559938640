import React from 'react'

const FeatureItem = ({ imgUrl, title, description }) => {
    return (
        <div className='p-6 bg-white dark:bg-dark2 rounded-2xl shadow-xl shadow-gray-200 dark:shadow-gray-800 hover:-translate-y-4 transition-transform duration-300'>
            <div className='flex space-x-2 items-center'>
                <img src={imgUrl} alt="" className='w-12' />
                <p className='text-xl text-default dark:text-gray-200 font-medium'>{title}</p>
            </div>
            <p className='ml-1.5 mt-2 text-secondary dark:text-gray-400'>{description}</p>
        </div>
    )
}

export default FeatureItem