import { Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../actions/notificationAction';
import httpService from '../../../services/httpService';
import storageService from '../../../services/storageService';

function JoinClass({ classJoinOpen, setClassJoinOpen }) {

    const [classCodeText, setClassCodeText] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleClassJoinOk = () => {
        if (classCodeText === "") {
            dispatch(showNotification({ type: "error", message: t("NOTIFICATION.JOIN_CLASS.EMPTY") }));
            return;
        }
        setLoading(true);
        httpService.put(`lesson/joinclass/${classCodeText}`, {}, storageService.getToken()).then((res) => {
            console.log(res);
            if (res.data.message === "joinClassSuccess")
                dispatch(showNotification({ type: "success", message: t("NOTIFICATION.JOIN_CLASS.SUCCESS") }));
            else if (res.data.message === "joinInviteCreated")
                dispatch(showNotification({ type: "success", message: t("NOTIFICATION.JOIN_CLASS.REQUEST_SENT") }));

            window.localStorage.setItem("classesRender", Date.now());
            setClassCodeText("");
            setLoading(false);
            setClassJoinOpen(false);


        }).catch((e) => {
            console.log(e);
            dispatch(showNotification({ type: "error", message: t("NOTIFICATION.JOIN_CLASS.ERROR") }));
            setClassCodeText("");
            setLoading(false);
            setClassJoinOpen(false);
        });
    };

    const handleClassJoinCancel = () => {
        setClassCodeText("");
        setClassJoinOpen(false);
    };

    return (
        <Modal title={t("CLASSES.JOIN_CLASS")} open={classJoinOpen} onOk={handleClassJoinOk} onCancel={handleClassJoinCancel} okText={t("CLASSES.JOIN")} cancelText={t("CLASSES.CANCEL")} confirmLoading={loading}>
            <Input placeholder={t("CLASSES.JOIN_CLASS_PLACEHOLDER")} value={classCodeText} onChange={(e) => setClassCodeText(e.currentTarget.value)} />
        </Modal>
    );
};

export default JoinClass;