import { GoogleLogin } from "@react-oauth/google";
import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Divider,
  Input,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showNotification } from "../../actions/notificationAction";
import useWindowDimensions from "../../hooks/window-dimentions";
import authorizationService from "../../services/authorizationService";


const ProLoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const [isLogin, setIsLogin] = useState(
    searchParams.get("register") === "false"
  );
  const theme = useSelector((state) => state.theme).theme;

  const navigate = useNavigate();

  useEffect(() => {
    setIsLogin(searchParams.get("register") === "false");
  }, [searchParams]);

  const sendApp = () => {
    navigate("/classes");
  };

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [registerForm, setRegisterForm] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    re_password: "",
  });

  useEffect(() => {
    document.title = t("LOGIN_REGISTER.PAGE_TITLE");
  }, [i18n.language]);

  const handleLoginFormChange = (e) => {
    const { name, value } = e.target;
    setLoginForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRegisterFormChange = (e) => {
    const { name, value } = e.target;
    setRegisterForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLoginKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  const handleRegisterKeyDown = (e) => {
    if (e.key === "Enter") {
      handleRegister(e);
    }
  };

  const responseMessage = (response) => {
    setLoading(true);
    authorizationService.logInWithGoogle(dispatch, navigate, t, response.credential).finally(() => {
      setLoading(false);
    });
  };
  const errorMessage = (error) => {
    dispatch(showNotification({
      type: "error",
      message: t('LOGIN.ERROR'),
      description: t('LOGIN.PLEASE_TRY_LATER')
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (loginForm.password.length < 6) {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.LOGIN.PASSWORD_ERROR.MESSAGE"),
          description: t("NOTIFICATION.LOGIN.PASSWORD_ERROR.CONTENT"),
        })
      );
      return;
    }

    if (loginForm.email.length < 6) {
      dispatch(
        showNotification({
          type: "error",
          message: t("LOGIN.EMAIL_ERROR.MESSAGE"),
          description: t("LOGIN.EMAIL_ERROR.CONTENT"),
        })
      );
      return;
    }

    setLoading(true);

    authorizationService.logIn(dispatch, navigate, t, loginForm, rememberMe).finally(() => {
      setLoading(false);
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (registerForm.name.length < 2) {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.LOGIN.NAME_ERROR.MESSAGE"),
          description: t("NOTIFICATION.LOGIN.NAME_ERROR.CONTENT"),
        })
      );
      return;
    }

    if (registerForm.surname.length < 2) {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.LOGIN.NAME_ERROR.MESSAGE"),
          description: t("NOTIFICATION.LOGIN.NAME_ERROR.CONTENT"),
        })
      );
      return;
    }

    if (registerForm.email.length < 6) {
      dispatch(
        showNotification({
          type: "error",
          message: t("LOGIN.EMAIL_ERROR.MESSAGE"),
          description: t("LOGIN.EMAIL_ERROR.CONTENT"),
        })
      );
      return;
    }

    if (registerForm.password.length < 6) {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.LOGIN.PASSWORD_ERROR.MESSAGE"),
          description: t("NOTIFICATION.LOGIN.PASSWORD_ERROR.CONTENT"),
        })
      );
      return;
    }

    if (registerForm.password !== registerForm.re_password) {
      dispatch(
        showNotification({
          type: "error",
          message: t("NOTIFICATION.LOGIN.PASSWORD_NOT_MATCH_ERROR.MESSAGE"),
          description: t("NOTIFICATION.LOGIN.PASSWORD_NOT_MATCH_ERROR.CONTENT"),
        })
      );
      return;
    }

    setLoading(true);

    // REGISTER MODEL
    const newUser = {
      name: registerForm.name,
      surname: registerForm.surname,
      email: registerForm.email,
      password: registerForm.password,
    };

    authorizationService.signUp(dispatch, t, newUser)
    .then(() => {
      setRegisterForm({
        name: "",
        surname: "",
        email: "",
        password: "",
        re_password: "",
      });
      navigate("/login?register=false");
    })
    .catch(() => {
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const MyCarousel = () => {
    return (
      <Col
        xxl={16}
        xl={14}
        lg={12}
        xs={24}
        className={`bg-[#F9F9FF] dark:bg-dark h-screen`}
      >
        <Row justify={"center"} align={"middle"} className="h-screen">
          <Col>
            <Carousel draggable autoplay autoplaySpeed={5000} dots={false}>
              <div>
                <div className="flex justify-center">
                  <img
                    src={require("../../assets/illustrations/login1.png")}
                    alt=""
                  />
                </div>
                <h2 className="text-center text-2xl mt-5">
                  {t('LOGIN.CAROUSEL.STEP1.TITLE')}
                </h2>
                <Row justify={"center"}>
                  <Col md={20} xs={22}>
                    <p className="text-center text-base text-secondary dark:text-gray-400 mt-2 pr-5 pl-5">
                      {t('LOGIN.CAROUSEL.STEP1.DESC')}
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <div className="flex justify-center">
                  <img
                    src={require("../../assets/illustrations/login2.png")}
                    alt=""
                  />
                </div>
                <h2 className="text-center text-2xl mt-5">
                  {t('LOGIN.CAROUSEL.STEP2.TITLE')}
                </h2>
                <Row justify={"center"}>
                  <Col md={20} xs={22}>
                    <p className="text-center text-base text-secondary dark:text-gray-400 mt-2 pr-5 pl-5">
                      {t('LOGIN.CAROUSEL.STEP2.DESC')}
                    </p>
                  </Col>
                </Row>
              </div>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={require("../../assets/illustrations/login3.png")}
                    alt=""
                  />
                </div>
                <h2 className="text-center text-2xl mt-5">
                  {t('LOGIN.CAROUSEL.STEP3.TITLE')}
                </h2>
                <Row justify={"center"}>
                  <Col md={20} xs={22}>
                    <p className="text-center text-base text-secondary dark:text-gray-400 mt-2 pr-5 pl-5">
                      {t('LOGIN.CAROUSEL.STEP3.DESC')}
                    </p>
                  </Col>
                </Row>
              </div>
            </Carousel>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <div className={`${theme === "dark" && "dark"}`}>
      <Row className={`bg-white dark:bg-dark2`} align={"middle"}>
        {width > 992 && <MyCarousel />}

        <Col xxl={6} xl={9} lg={12} xs={24} style={{ padding: 20 }}>
          {isLogin ? (
            <>
              {/* <Row align={"middle"} style={{ marginTop: 40 }}>
                <Col>
                  <img
                    src={require("../../assets/images/logo-color.png")}
                    alt=""
                    className="rounded-md w-8"
                  />
                </Col>
                <Col>
                  <p className="text-dark2 dark:text-gray-200 ml-2.5 font-bold text-xl">
                    INTELLIGRADE
                  </p>
                </Col>
              </Row> */}
              <img
                src={require(`../../assets/images/${theme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)}
                alt=""
                className="rounded-md w-48 -ml-1"
              />
              <h2 className="text-secondary dark:text-gray-300 mt-4 text-xl">
                {t('LOGIN.WELCOME')}
              </h2>
              <p className="text-light dark:text-gray-400 mt-1">
                {t('LOGIN.TOCONTINUE')}
              </p>
              <form onKeyDown={handleLoginKeyDown}>
                <p className="text-sm text-secondary dark:text-gray-500  mt-10">
                  {t('LOGIN.EMAIL_ADDRESS')}
                </p>
                <Input
                  size="large"
                  className="mt-1"
                  value={loginForm.email}
                  onChange={handleLoginFormChange}
                  name="email"
                />
                <div className="flex justify-between mt-2.5 items-center">
                  <p className="text-sm text-secondary dark:text-gray-500">
                    {t('LOGIN.PASSWORD')}
                  </p>
                  <Button
                    type="link"
                    className="pr-0 pb-0"
                    onClick={() => navigate("/forgotpassword")}
                  >
                    {t('LOGIN.FORGOT_PASSWORD')}
                  </Button>
                </div>
                <Input.Password
                  size="large"
                  className="mt-1"
                  value={loginForm.password}
                  onChange={handleLoginFormChange}
                  name="password"
                />
                <Checkbox className="mt-5" onChange={(e) => setRememberMe(e.target.checked)}>
                  <p className="text-secondary dark:text-gray-400">
                    {t('LOGIN.REMEMBER_ME')}
                  </p>
                </Checkbox>
                <br />
                <Button
                  size="large"
                  block
                  type="primary"
                  className="mt-10"
                  onClick={handleLogin}
                  loading={loading}
                >
                  {t('LOGIN.LOGIN')}
                </Button>
              </form>
              <p className="text-light text-center mt-5">
                {t('LOGIN.DONT_HAVE_ACCOUNT')}{" "}
                <a
                  className="font-bold text-primary"
                  onClick={() => navigate("/login?register=true")}
                >
                  {t('LOGIN.CREATE_NOW')}
                </a>
              </p>
              <Row align={"middle"} justify={"space-between"} className="mt-10">
                <Col span={10}>
                  <Divider className="m-0" />
                </Col>
                <Col span={4}>
                  <p className="text-center text-light">
                    {t('LOGIN.OR')}
                  </p>
                </Col>
                <Col span={10}>
                  <Divider className="m-0" />
                </Col>
              </Row>
              <div className="flex justify-center mt-10">
                <Space size={15}>
                  <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                    text="signin_with"
                    context="signin"
                    locale={i18n.language}
                  />
                </Space>
              </div>
            </>
          ) : (
            <>
              {/* <Row align={"middle"} className="mt-10">
                <Col>
                  <img
                    src={require("../../assets/images/logo-color.png")}
                    alt=""
                    className="rounded-md w-8"
                  />
                </Col>
                <Col>
                  <p className="text-dark2 dark:text-gray-200 ml-2.5 font-bold text-xl">
                    INTELLIGRADE
                  </p>
                </Col>
              </Row> */}
              <img
                src={require(`../../assets/images/${theme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)}
                alt=""
                className="rounded-md w-48 -ml-1"
              />
              <h2 className="text-secondary dark:text-gray-300 mt-4 text-xl">
                {t('LOGIN.WELCOME')}
              </h2>
              <p className="text-light dark:text-gray-400 mt-1">
                {t('LOGIN.TOCONTINUEREGISTER')}
              </p>

              <form onKeyDown={handleRegisterKeyDown}>
                <Row justify={"space-between"}>
                  <Col span={11}>
                    <p className="text-sm text-secondary dark:text-gray-500 mt-10">
                      {t('LOGIN.NAME')}
                    </p>
                    <Input
                      size="large"
                      className="mt-1"
                      value={registerForm.name}
                      onChange={handleRegisterFormChange}
                      name="name"
                    />
                  </Col>
                  <Col span={11}>
                    <p className="text-sm text-secondary dark:text-gray-500  mt-10">
                      {t('LOGIN.SURNAME')}
                    </p>
                    <Input
                      size="large"
                      className="mt-1"
                      value={registerForm.surname}
                      onChange={handleRegisterFormChange}
                      name="surname"
                    />
                  </Col>
                </Row>

                <p className="text-sm text-secondary dark:text-gray-500  mt-5">
                  {t('LOGIN.EMAIL_ADDRESS')}
                </p>
                <Input
                  size="large"
                  className="mt-1"
                  value={registerForm.email}
                  onChange={handleRegisterFormChange}
                  name="email"
                />

                <p className="text-sm text-secondary dark:text-gray-500  mt-5">
                  {t('LOGIN.PASSWORD')}
                </p>
                <Input.Password
                  size="large"
                  className="mt-1"
                  value={registerForm.password}
                  onChange={handleRegisterFormChange}
                  name="password"
                />

                <p className="text-sm text-secondary dark:text-gray-500  mt-5">
                  {t('LOGIN.PASSWORD_AGAIN')}
                </p>
                <Input.Password
                  size="large"
                  className="mt-1"
                  value={registerForm.re_password}
                  onChange={handleRegisterFormChange}
                  name="re_password"
                />
                <Checkbox className="mt-5">
                  <p className="text-secondary dark:text-gray-400 ">
                    {t("LOGIN.REMEMBER_ME")}
                  </p>
                </Checkbox>
                <br />
                <Button
                  size="large"
                  block
                  type="primary"
                  className="mt-10"
                  onClick={handleRegister}
                  loading={loading}
                >
                  {t('LOGIN.REGISTER')}
                </Button>
              </form>

              <p className="text-light text-center mt-5">
                {t('LOGIN.ALREADY_HAVE_ACCOUNT')}{" "}
                <a
                  className="font-bold text-primary"
                  onClick={() => navigate("/login?register=false")}
                >
                  {t('LOGIN.LOGIN_NOW')}
                </a>
              </p>
              <Row align={"middle"} justify={"space-between"} className="mt-10">
                <Col span={10}>
                  <Divider className="m-0" />
                </Col>
                <Col span={4}>
                  <p className="text-center text-light">
                    {t('LOGIN.OR')}
                  </p>
                </Col>
                <Col span={10}>
                  <Divider className="m-0" />
                </Col>
              </Row>
              <div className="flex justify-center mt-10">
                <Space size={15}>
                  <GoogleLogin
                    onSuccess={responseMessage}
                    onError={errorMessage}
                    text="continue_with"
                    context="signup"
                    locale={i18n.language}
                  />
                </Space>
              </div>
            </>
          )}
        </Col>

        {width < 992 && <MyCarousel />}
      </Row>
    </div>
  );
};

export default ProLoginPage;
