import { Icon } from '@iconify/react';
import { Button, Divider, Empty } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationType } from '../../models/enums/NotificationType.js';
import httpService from "../../services/httpService.js";
import storageService from '../../services/storageService.js';
import NotificationInviteItem from './components/NotificationInviteItem';
import NotificationJoinRequest from './components/NotificationJoinRequest.jsx';
import NotificationNoteItem from './components/NotificationNoteItem';
import NotificationObjectionItem from './components/NotificationObjectionItem';
import NotificationRemovedFromLessonItem from './components/NotificationRemovedFromLessonItem';
import NotificationShareItem from './components/NotificationShareItem';
import NotificationSkeletonLoading from './components/NotificationSkeletonLoading.jsx';

const NotificationContent = ({ notifications, setNotifications, notificationFlowLoading, notificationTop }) => {

    const [reading, setReading] = useState(false);
    const theme = useSelector((state) => state.theme).theme;
    const dispatch = useDispatch();

    const getNotificationWithType = (notification) => {
        switch (notification.type) {
            case NotificationType.ShareAssignment:
                return <NotificationShareItem notification={notification} />;

            case NotificationType.ShareQuiz:
                return <NotificationShareItem notification={notification} />;

            case NotificationType.SharePost:
                return <NotificationShareItem notification={notification} />;

            case NotificationType.ShareSurvey:
                return <NotificationShareItem notification={notification} />;

            case NotificationType.AssignmentGiveNote:
                return <NotificationNoteItem notification={notification} />;

            case NotificationType.QuizGiveNote:
                return <NotificationNoteItem notification={notification} />;

            case NotificationType.Invite:
                return <NotificationInviteItem notification={notification} />;

            case NotificationType.CreateObjection:
                return <NotificationObjectionItem notification={notification} />;

            case NotificationType.AnswerQuizObjection:
                return <NotificationObjectionItem notification={notification} />;

            case NotificationType.AnswerAssignmentObjection:
                return <NotificationObjectionItem notification={notification} />;

            case NotificationType.RemovedFromLesson:
                return <NotificationRemovedFromLessonItem notification={notification} />;

            case NotificationType.JoinRequest:
                return <NotificationJoinRequest notification={notification} />;
            default:
                return true ? <></> : <p>Bildirim Alınamadı</p>;

        }
    };

    const readAllNotification = () => {
        setReading(true);
        httpService.put("notification/readAllNotifications", {}, storageService.getToken()).then(res => {
            const readedNotifications = notifications.map((item) => ({
                ...item,
                isSeen: true
            }));

            setNotifications(readedNotifications);
        }).catch(e => console.log(e)).finally(() => setReading(false));
    };

    return (
        <div className={`w-96 ${theme === "dark" && 'dark'} overflow-y-auto`} style={{ maxHeight: "25rem" }} id='notificationContainer'>
            <div className='flex justify-between items-center pl-2' key={-1}>
                <p className='text-gray-900 dark:text-gray-200 text-base'>Bildirimler</p>
                <Button type={"text"} className='flex space-x-2 items-center' onClick={readAllNotification} loading={reading}>
                    <p className='text-gray-600 dark:text-gray-400 text-xs'>Tümünü Okundu Olarak İşaretle</p>
                    <Icon icon="ci:check-all" className='text-gray-600 dark:text-gray-400' />
                </Button>
            </div>
            <Divider className='mt-1 mb-1' />
            {
                (notifications && notifications.length > 0)
                    ?
                    notifications.map((notification, index) => (

                        <div key={index}>
                            {getNotificationWithType(notification)}
                            <Divider className='mt-1 mb-1' />
                        </div>
                    ))
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
            {notificationFlowLoading &&
                Array(notificationTop).fill(0).map((_, index) => (
                    <>
                        <NotificationSkeletonLoading />
                        <Divider className='mt-1 mb-1' />
                    </>
                ))
            }
        </div>
    );
};

export default NotificationContent;