import { CHANGE_PAGE } from "../actions/pageAction";
import storageService from "../services/storageService";

const initialState = storageService.getCurrentPage();

const pageReducer = (state = initialState, action) => {

    switch(action.type){
        case CHANGE_PAGE:
            storageService.changePage(action.payload);
            return action.payload
        default:
            return state
    }
}

export default pageReducer;