import { Skeleton } from 'antd';
import React from 'react';

const NotificationSkeletonLoading = () => {
    return (
        <div className='flex justify-start items-center w-full ps-2'>
            <Skeleton
                className='h-min flex items-center justify-start'
                avatar={{
                }}
                active
                paragraph={{
                    rows: 0,
                }}
                title={{
                    width: "90%",
                }}
            />
        </div>
    );
};

export default NotificationSkeletonLoading;