import Cookies from "js-cookie";

class StorageService {
  addToken = (token) => {
    Cookies.remove("intelligrade-token", { secure: true });
    Cookies.set("intelligrade-token", token, { secure: true });
  };

  removeToken = () => {
    Cookies.remove("intelligrade-token", { secure: true });
  };

  getToken = () => {
    return Cookies.get("intelligrade-token", { secure: true });
  };

  addRefreshToken = (token) => {
    Cookies.remove("intelligrade-refreshToken", { secure: true });
    Cookies.set("intelligrade-refreshToken", token, { secure: true });
  };

  removeRefreshToken = () => {
    Cookies.remove("intelligrade-refreshToken", { secure: true });
  };

  getRefreshToken = () => {
    return Cookies.get("intelligrade-refreshToken", { secure: true });
  };

  changeLanguage = (lang) => {
    localStorage.setItem("intelligrade-lang", lang);
  };

  getLanguage = () => {
    const lang = localStorage.getItem("intelligrade-lang");

    return lang ? lang : "tr";
  };

  changePage = (page) => {
    localStorage.setItem("page", page);
  };

  getCurrentPage = () => {
    const page = localStorage.getItem("page");

    return page ? page : "0";
  };

  getCurrentTheme = () => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      return theme;
    } else {
      localStorage.setItem("theme", "light");
      return "light";
    }
  };

  setCurrentTheme = (theme) => {
    theme && localStorage.setItem("theme", theme);
  };
}

const storageService = new StorageService();

export default storageService;
