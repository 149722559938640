import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux';

const LoadingAnimation = () => {
    const theme = useSelector((state) => state.theme).theme;

    return (
        <div className={`${theme === "dark" && 'dark'}`}>
            <div className={`flex justify-center items-center w-screen h-screen dark:bg-dark`}>
                <Lottie animationData={require("../assets/animations/loading.json")} className='w-48 h-48' />
            </div>
        </div>
    )
}

export default LoadingAnimation