import { Avatar, Popover } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { utcToLocaleLangFormat } from '../../../helpers/helperFunctions';
import httpService from '../../../services/httpService';

const NotificationJoinRequest = ({ notification }) => {
    const navigate = useNavigate();
    const navigateToContent = () => {

        httpService.put(`notification/readNotification/${notification.id}`).then().catch();
        navigate(`/students/${notification.contentClassId}`);
    };
    return (
        <div onClick={() => navigateToContent()} className='flex space-x-2 items-start hover:bg-gray-100 dark:hover:bg-dark2 cursor-pointer rounded-md transition-all duration-300 p-2'>
            {notification.createdProfilePicture && notification.createdProfilePicture !== ""
                ?
                <Avatar size={'large'} src={notification.createdProfilePicture} />
                :
                <Avatar size={'large'} className='bg-[#5161ce] w-12'>{notification.createdUserName[0]}</Avatar>
            }
            <div>
                <Popover className='dark:text-white' content={notification.createdUserName + ", " + notification.contentClassName + " sınıfına katılmak istiyor."}>
                    <p className='text-base flex-1 '>{notification.createdUserName} <span className='text-primary text-sm'>katılmak istiyor </span> {notification.contentTitle.length > 15 ? `${notification.contentTitle.slice(0, 15)}...` : notification.contentTitle}</p>
                </Popover>
                <div className='flex space-x-2 items-center'>
                    <p className='text-xs text-gray-400'>{utcToLocaleLangFormat(notification.createdAt)}</p>
                    {/* <Avatar size={4} className='bg-gray-300' />
                    <p className='text-xs text-gray-400'>{notification.contentClassName}</p> */}
                </div>
            </div>
            {
                !notification.isSeen && (
                    <Avatar size={6} className='bg-[#5161ce]' />
                )
            }
        </div>
    );
};

export default NotificationJoinRequest;