import jwtDecode from "jwt-decode";
import { setCurrentUser } from "../actions/userAction";
import httpService from "./httpService";
import storageService from "./storageService";

export const getCurrentUser = async (dispatch) => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;

  const res = await httpService.get("user/getuser", token).catch((err) => {
    window.location = "/login";
    storageService.removeToken();
  });

  dispatch(setCurrentUser(res.data.data));

  dispatch(setCurrentUser(res.data.data));

  return res.data.statusCode === 200 ? res.data.data : undefined;
};

export const getUserIdByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).UserId;
};

export const getEmailByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).Email;
};

export const getNameSurnameByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).Name + " " + jwtDecode(token).Surname;
};

export const getNameByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).Name;
};

export const getSurnameByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).Surname;
};

export const getProfilePhotoUrlByToken = () => {
  const token = storageService.getToken();
  if (token === undefined) return undefined;
  return jwtDecode(token).ProfilePhoto;
};

export const getEmailByGoogleToken = (token) => {
  if (token === undefined) return undefined;
  return jwtDecode(token).email;
};

export const getNameByGoogleToken = (token) => {
  if (token === undefined) return undefined;
  return jwtDecode(token).given_name;
};

export const getSurnameByGoogleToken = (token) => {
  if (token === undefined) return undefined;
  return jwtDecode(token).family_name;
};
