import { Icon } from "@iconify/react";
import { Button, Col, Collapse, Divider, Form, Row, Select, Tooltip } from "antd";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { showNotification } from "../../actions/notificationAction.js";
import { GptLanguageType } from "../../models/enums/GptLanguageType.js";
import httpService from "../../services/httpService.js";
import storageService from "../../services/storageService.js";
const TestAssignment = () => {
  const [file, setFile] = useState(null);
  const [fail, setFail] = useState(false);
  const [testAssignmentHistory, setTestAssignmentHistory] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [changePage, setChangePage] = useState(false);
  const [language, setLanguage] = useState(GptLanguageType.Tr);
  const dispatch = useDispatch();
  const { assignmentId } = useParams();
  const { t } = useTranslation();

  const handleHomeworkFileChange = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 52428800) {
        dispatch(
          showNotification({
            type: "info",
            message: t("ASSIGNMENT_DETAIL.FILE_SIZE_TOO_LARGE"),
          })
        );
        return;
      }
      setFile(e.target.files[0]);
      dispatch(
        showNotification({
          type: "success",
          title: t("ASSIGNMENT_DETAIL.SUCCESS"),
          message: t("ASSIGNMENT_DETAIL.FILE_UPLOAD_SUCCESS"),
        })
      );
    } else {
      dispatch(
        showNotification({
          type: "error",
          title: t("ASSIGNMENT_DETAIL.ERROR"),
          message: t("ASSIGNMENT_DETAIL.FILE_UPLOAD_ERROR"),
        })
      );
    }
  };
  useEffect(() => {
    setPageLoading(true);
    httpService
      .get(
        `assignment/getTestHomeworks/${assignmentId}`,
        storageService.getToken()
      )
      .then((res) => {
        setTestAssignmentHistory(res.data.data);
      })
      .catch((e) => {
        dispatch(
          showNotification({
            type: "error",
            title: t("ASSIGNMENT_DETAIL.ERROR"),
            message: t("ASSIGNMENT_DETAIL.TEST_HISTORY_ERROR"),
          })
        );
      }).finally(() => setPageLoading(false));
  }, []);


  const testAssignment = async () => {
    setFeedback(null);
    setLoading(true);
    setChangePage(true);
    const formData = new FormData();
    formData.append("File", file);
    formData.append("FileName", file.name);
    formData.append("Language", language);
    httpService
      .post(
        `ai/gettestnote/${assignmentId}`,
        formData,
        storageService.getToken()
      )
      .then((res) => {
        setFeedback({
          note: res.data.data?.note,
          successfulyImprovementAboutHomework:
            res.data.data?.successfulyImprovementAboutHomework,
          needsImprovementAboutHomework:
            res.data.data?.needsImprovementAboutHomework,
        });
        setFail(false);
      })
      .catch((e) => {
        if (e.response.data.message === "testHomeworkExceeded") {
          dispatch(
            showNotification({
              type: "error",
              title: t("ASSIGNMENT_DETAIL.TEST_LIMIT_TITLE"),
              message: t("ASSIGNMENT_DETAIL.TEST_LIMIT_EXCEEDED"),
            })
          );
        } else {
          dispatch(
            showNotification({
              type: "error",
              title: t("ASSIGNMENT_DETAIL.ERROR"),
              message: t("ASSIGNMENT_DETAIL.TEST_ASSIGNMENT_ERROR"),
            })
          );
        }
        setFail(true);
      })
      .finally(() => setLoading(false));
  };

  const handleFileDownload = async (fileUrl) => {
    var link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.setAttribute("download", fileUrl);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangeFeedbackContent = (selectedFeedbackContent) => {
    setFeedback(selectedFeedbackContent);
  };

  return (
    <>
      {
        pageLoading ? (
          <div className="flex justify-center items-center h-[90vh] "  >
            <Lottie animationData={require("../../assets/animations/loading.json")} className='w-48 h-48' />
          </div >
        ) : (testAssignmentHistory.length > 0 || changePage ? (
          <div className="relative w-full bg-[#CBD2FF] dark:bg-dark overflow-y-auto h-[180vh] lg:h-[90vh] mt-1 rounded-2xl p-6">
            <img
              src={require("../../assets/images/test1.png")}
              alt=""
              className="absolute top-10 left-10 opacity-50 blur-sm w-6/12 md:w-5/12 lg:w-4/12 xl:w-3/12"
            />
            <img
              src={require("../../assets/images/test2.png")}
              alt=""
              className="absolute bottom-10 left-96 opacity-50 blur-sm w-5/12 md:w-4/12 lg:w-3/12 xl:w-2/12"
            />
            <img
              src={require("../../assets/images/test3.png")}
              alt=""
              className="absolute top-24 right-24 opacity-50 blur-sm w-7/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            />
            <input
              type="file"
              onChange={handleHomeworkFileChange}
              id="fileInputTest"
              className="hidden"
            />
            <Row className="h-full" align={"top"}>
              <Col xl={16} lg={13} xs={22}>
                <div className="bg-white dark:bg-dark2 h-[85vh] rounded-xl opacity-70 dark:opacity-80 py-4 px-8">
                  <p className="text-2xl text-gray-900 dark:text-gray-200">
                    {t('ASSIGNMENT_DETAIL.FEEDBACK')}
                  </p>
                  <Divider className="bg-gray-300 mt-4" />
                  {!loading ? (
                    feedback?.successfulyImprovementAboutHomework?.length > 0 ||
                      feedback?.needsImprovementAboutHomework?.length > 0 ? (
                      <>
                        {feedback?.successfulyImprovementAboutHomework?.map(
                          (item, index) => (
                            <Collapse
                              className="mt-2 bg-green-100 dark:bg-green-900"
                              items={[
                                {
                                  key: item.stepNo,
                                  label: (
                                    <div className="flex justify-between items-center -mt-1">
                                      <p>{item.stepTitle}</p>
                                      <Tooltip title="Güçlü Taraf">
                                        <Button
                                          type="ghost"
                                          shape="circle"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Icon
                                            icon="material-symbols:check"
                                            className="text-lg mt-0.5 text-green-600"
                                          />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  ),
                                  children: <p>{item.stepDescription}</p>,
                                },
                              ]}
                            />
                          )
                        )}
                        {feedback?.needsImprovementAboutHomework?.map(
                          (item, index) => (
                            <Collapse
                              className="mt-2 bg-red-100 dark:bg-red-900"
                              items={[
                                {
                                  key: item.stepNo,
                                  label: (
                                    <div className="flex justify-between items-center -mt-1">
                                      <p>{item.stepTitle}</p>
                                      <Tooltip title="Zayıf Taraf">
                                        <Button
                                          type="ghost"
                                          shape="circle"
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Icon
                                            icon="material-symbols:close"
                                            className="text-lg mt-0.5 text-red-600 dark:text-white"
                                          />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  ),
                                  children: <p>{item.stepDescription}</p>,
                                },
                              ]}
                            />
                          )
                        )}
                      </>
                    ) : !fail ? (
                      testAssignmentHistory.length > 2 ? (
                        <div className="mt-20">
                          <div className="flex justify-center">
                            <img
                              src={require("../../assets/illustrations/test_complated.png")}
                              alt=""
                              className="w-60"
                            />
                          </div>
                          <Row justify={"center"}>
                            <Col xxl={12} lg={20} xs={18}>
                              <p className="mt-8 text-center text-base text-gray-700 dark:text-gray-400">
                                {t("ASSIGNMENT_DETAIL.TEST_LIMIT_EXCEEDED")}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="text-center mt-20">
                          <img
                            src={require("../../assets/illustrations/test_assignment.png")}
                            alt=""
                            className="w-60"
                          />
                          <p className="mt-2 text-base text-gray-700 dark:text-gray-400">
                            {t("ASSIGNMENT_DETAIL.CHOOSE_FILE_TO_TEST")}
                          </p>
                        </div>
                      )
                    ) : (
                      <div className="text-center mt-20">
                        <img
                          src={require("../../assets/illustrations/error.png")}
                          alt=""
                          className="w-60"
                        />
                        <p className="mt-8 text-base text-gray-700 dark:text-gray-400">
                          {t("ASSIGNMENT_DETAIL.TEST_FAILED")}
                        </p>
                      </div>
                    )
                  ) : (
                    <div>
                      <div className="flex justify-center items-center">
                        <Lottie
                          animationData={require("../../assets/animations/test_assignment.json")}
                          className="md:w-80 w-60 mt-10"
                        />
                      </div>
                      <p className="text-center text-base md:text-xl text-gray-700 dark:text-gray-400">
                        {t("ASSIGNMENT_DETAIL.TESTING_YOUR_ASSIGNMENT")}
                      </p>
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={8} lg={11} xs={22} className="pl-0 lg:pl-4 -mt-60 lg:mt-0">
                <div className="opacity-70 rounded-2xl p-4 bg-white dark:bg-dark2">
                  <p className="text-center text-xl 2xl:text-2xl text-gray-900 dark:text-gray-200">
                    {t("ASSIGNMENT_DETAIL.TEST_YOUR_ASSIGNMENT")}
                  </p>
                  <Divider className="my-3" />
                  <div
                    className={`text-gray-700 dark:text-gray-400 ${testAssignmentHistory.length > 2
                      ? "cursor-not-allowed"
                      : "hover:text-gray-500 dark:hover:text-gray-200 cursor-pointer"
                      } transition-all duration-300 `}
                    onClick={() => document.getElementById("fileInputTest").click()}
                  >
                    <div className="flex justify-center mt-5">
                      <Icon
                        icon="material-symbols:upload"
                        className="text-[5rem]"
                      />
                    </div>
                    <p className="text-center text-base 2xl:text-lg ">
                      {t('ASSIGNMENT_DETAIL.TEST_ASSIGNMENT_DESC')}
                    </p>
                  </div>
                  <Form className="mt-4">
                    <Form.Item label={t("ASSIGNMENT_DETAIL.SELECT_LANGUAGE")}>
                      <Select
                        name="language"
                        value={language}
                        options={[
                          {
                            value: GptLanguageType.Tr,
                            label: t("ASSIGNMENT_DETAIL.LANGUAGES.TR"),
                          },
                          {
                            value: GptLanguageType.En,
                            label: t("ASSIGNMENT_DETAIL.LANGUAGES.EN"),
                          },
                        ]}
                        onChange={(value) => setLanguage(value)}
                      />
                    </Form.Item>
                  </Form>
                  {file !== null && (
                    <div className="flex justify-between items-center mt-10 space-x-2">
                      <div className="p-2 border-2 border-solid border-gray-300 bg-gray-200 dark:bg-dark dark:border-gray-700 flex-1 rounded-md flex space-x-2 items-center">
                        <Icon
                          icon="gravity-ui:file"
                          className="text-gray-900 dark:text-gray-200"
                        />
                        <p className="text-gray-900 dark:text-gray-200">
                          {file?.name}
                        </p>
                      </div>
                      <Button
                        danger
                        type="text"
                        shape="circle"
                        onClick={() => {
                          setFile(null);
                          setChangePage(false);
                        }}
                      >
                        <Icon
                          icon="fluent:delete-12-regular"
                          className="text-2xl"
                        />
                      </Button>
                    </div>
                  )}

                  <Button
                    onClick={() => testAssignment()}
                    size="large"
                    disabled={loading || file === null}
                    block
                    type="primary"
                    className="mt-5 bg-gradient-to-r from-[#5161ce] to-pink-500 hover:opacity-80 border-none"
                  >
                    <Icon icon="mdi:magic" className="mt-0.5 text-white" />
                    <span className="ml-2 text-white">{t("ASSIGNMENT_DETAIL.TEST_YOUR_ASSIGNMENT")}</span>
                  </Button>
                </div>
                {testAssignmentHistory.length > 0 && (
                  <div className="opacity-70 rounded-2xl p-4 bg-white dark:bg-dark2 mt-4">
                    <p className="text-center text-xl 2xl:text-2xl text-gray-900 dark:text-gray-200">
                      {t("ASSIGNMENT_DETAIL.TEST_HISTORY")}
                    </p>
                    <Divider className="my-3" />
                    <div className="overflow-y-auto h-[30vh]">
                      {testAssignmentHistory.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center cursor-pointer"
                          onClick={() => handleChangeFeedbackContent(item)}
                        >
                          <div className="flex items-center space-x-2  text-gray-700 dark:text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 transition-all duration-300">
                            <Icon
                              icon="mdi:file-document-edit-outline"
                              className="text-2xl"
                            />
                            <p>{item.fileName}</p>
                          </div>
                          <Button
                            onClick={() => handleFileDownload(item.fileUrl)}
                            type="text"
                            shape="circle"
                            className="border-none"
                            icon={
                              <Icon
                                icon="material-symbols:download"
                                className="text-2xl text-gray-700 dark:text-gray-400"
                              />
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <div className="relative w-full bg-[#CBD2FF] dark:bg-dark overflow-y-auto h-[180vh] lg:h-[90vh] mt-1 rounded-2xl p-6">
            <img
              src={require("../../assets/images/test1.png")}
              alt=""
              className="absolute top-10 left-10 opacity-50 blur-sm w-6/12 md:w-5/12 lg:w-4/12 xl:w-3/12"
            />
            <img
              src={require("../../assets/images/test2.png")}
              alt=""
              className="absolute bottom-10 left-96 opacity-50 blur-sm w-5/12 md:w-4/12 lg:w-3/12 xl:w-2/12"
            />
            <img
              src={require("../../assets/images/test3.png")}
              alt=""
              className="absolute top-24 right-24 opacity-50 blur-sm w-7/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            />
            <input
              type="file"
              onChange={handleHomeworkFileChange}
              id="fileInputTest"
              className="hidden"
            />
            <Row justify={"center"} align={"middle"} className="h-full">
              <Col
                xxl={8}
                xl={10}
                lg={12}
                md={16}
                sm={20}
                xs={22}
                className="opacity-70 rounded-2xl p-4 bg-white dark:bg-dark2"
              >
                <p className="text-center text-2xl text-gray-900 dark:text-gray-200">
                  {t("ASSIGNMENT_DETAIL.TEST_YOUR_ASSIGNMENT")}
                </p>
                {file === null ? (
                  <div className="text-gray-700 dark:text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 cursor-pointer transition-all duration-300">
                    <div className="flex justify-center mt-5">
                      <Icon
                        icon="material-symbols:upload"
                        className="text-[5rem]"
                        onClick={() =>
                          document.getElementById("fileInputTest").click()
                        }
                      />
                    </div>
                    <p className="text-center text-lg">
                      {t("ASSIGNMENT_DETAIL.CHOOSE_FILE_TO_TEST")}
                    </p>
                  </div>
                ) : (
                  <div className="flex justify-between items-center mt-10 space-x-2">
                    <div className="p-2 border-2 border-solid border-gray-300 bg-gray-200 dark:bg-dark dark:border-gray-700 flex-1 rounded-md flex space-x-2 items-center">
                      <Icon
                        icon="gravity-ui:file"
                        className="text-gray-900 dark:text-gray-200"
                      />
                      <p className="text-gray-900 dark:text-gray-200">
                        {file?.name}
                      </p>
                    </div>
                    <Button
                      danger
                      type="text"
                      shape="circle"
                      onClick={() => setFile(null)}
                    >
                      <Icon icon="fluent:delete-12-regular" className="text-2xl" />
                    </Button>
                  </div>
                )}
                <Form className="mt-10">
                  <Form.Item label={t("ASSIGNMENT_DETAIL.SELECT_LANGUAGE")}>
                    <Select
                      name="language"
                      value={language}
                      options={[
                        {
                          value: GptLanguageType.Tr,
                          label: t("ASSIGNMENT_DETAIL.LANGUAGES.TR"),
                        },
                        {
                          value: GptLanguageType.En,
                          label: t("ASSIGNMENT_DETAIL.LANGUAGES.EN"),
                        },
                      ]}
                      onChange={(value) => setLanguage(value)}
                    />
                  </Form.Item>
                </Form>
                <Button
                  onClick={testAssignment}
                  size="large"
                  block
                  disabled={file === null}
                  type="primary"
                  className="bg-gradient-to-r from-[#5161ce] to-pink-500 hover:opacity-80 border-none"
                >
                  <Icon icon="mdi:magic" className="mt-0.5 text-white" />
                  <span className="ml-2 text-white">{t("ASSIGNMENT_DETAIL.TEST_YOUR_ASSIGNMENT")}</span>
                </Button>
              </Col>
            </Row>
          </div>
        ))
      }
    </>
  );

};

export default TestAssignment;
