import { Icon } from "@iconify/react";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { changePage } from "../actions/pageAction";
import httpService from "../services/httpService";
import storageService from "../services/storageService";


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}




const ProSideBar = ({ collapsed, setCollapsed, inDrawer, setOpenDrawer }) => {

    const { t, i18n } = useTranslation();
    const { classId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.page);
    const theme = useSelector((state) => state.theme).theme;
    const [isTeacher, setIsTeacher] = useState(false);

    useEffect(() => {
        if (classId) {
            httpService.get(`user/isTeacher/${classId}`, storageService.getToken()).then(res => {
                setIsTeacher(res.data.data);
            }).catch(e => setIsTeacher(false));
        }
    }, [classId]);

    const items = [
        getItem("Genel", "grp1", null, [getItem(t("SIDEBAR.MY_CLASSES"), "1", <Icon icon="radix-icons:dashboard" />)], "group"),
        getItem(
            classId && t("SIDEBAR.CLASS"),
            "grp2",
            null,
            classId ?
                isTeacher
                    ?
                    [
                        getItem(t("SIDEBAR.FLOW"), "2", <Icon icon="iconamoon:home" />),
                        getItem(t("SIDEBAR.USERS"), "3", <Icon icon="ci:users" />),
                        getItem(t("SIDEBAR.OBJECTIONS"), "4", <Icon icon="icon-park-outline:reject" />),
                        getItem(t("SIDEBAR.CLASS_FILES"), "5", <Icon icon="ic:outline-folder" />),
                    ]
                    :
                    [
                        getItem(t("SIDEBAR.FLOW"), "2", <Icon icon="iconamoon:home" />),
                        getItem(t("SIDEBAR.USERS"), "3", <Icon icon="ci:users" />),
                        getItem(t("SIDEBAR.CLASS_FILES"), "5", <Icon icon="ic:outline-folder" />),
                    ]
                :
                [],
            "group"
        ),
        // getItem(
        //     "Kişisel",
        //     "grp3",
        //     null,
        //     [
        //         getItem("Geri Bildirimlerim", "6", <Icon icon="mdi:push-notification-outline" />),
        //         getItem("İstatistiklerim", "8", <Icon icon="akar-icons:statistic-up" />),
        //         getItem("Dosyalarım", "9", <Icon icon="solar:folder-with-files-outline" />),
        //     ],
        //     "group"
        // ),
    ];

    const navigations = {
        1: "/classes",
        2: `/classes/${classId}`,
        3: `/students/${classId}`,
        4: `/objection/${classId}`,
        5: `/class-files/${classId}`,
    };

    const menuOnClick = (e) => {
        dispatch(changePage(e.key));
        navigate(navigations[e.key]);
        if (inDrawer) {
            setOpenDrawer(false);
        }
    };

    return (
        <div>
            <Menu
                mode="inline"
                inlineCollapsed={!inDrawer && collapsed}
                selectedKeys={[currentPage]}
                className="h-screen border-r border-gray-300"
                onClick={menuOnClick}
            >

                {/* <div className="flex items-center justify-center mt-4 mb-8">
                    <img src={require("../assets/images/logo-color.png")} alt="" className="w-8 rounded-md" />
                    {
                        !collapsed && (
                            <p style={{ fontWeight: "bold", fontSize: "1.1rem", marginLeft: 6 }}>INTELLIGRADE</p>
                        )
                    }
                </div> */}
                <div onClick={() => navigate("/classes")} className="flex justify-center mt-4 mb-8 cursor-pointer">
                    {
                        collapsed
                            ?
                            <img src={require(`../assets/images/${theme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)} alt="" className="w-16 rounded-md" />
                            :
                            <img src={require(`../assets/images/${theme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)} alt="" className="w-36 rounded-md" />
                    }
                </div>


                {items.map(item => {
                    if (item.type === "group") {
                        return (
                            collapsed
                                ?
                                item.children.map(subItem => (
                                    <Menu.Item key={subItem.key} icon={subItem.icon}>
                                        {subItem.label}
                                    </Menu.Item>
                                ))
                                :
                                <Menu.ItemGroup key={item.key} icon={item.icon} title={item.label}>
                                    {item.children.map(subItem => (
                                        <Menu.Item key={subItem.key} icon={subItem.icon}>
                                            {subItem.label}
                                        </Menu.Item>
                                    ))}
                                </Menu.ItemGroup>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.key} icon={item.icon}>
                                {item.label}
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        </div>
    );
};

export default ProSideBar;
