import storageService from "../services/storageService";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const changePage = (page) => {
    storageService.changePage(page)
    return {
        type: CHANGE_PAGE,
        payload: page
    }
}