import { Icon } from "@iconify/react";
import { Button, Col, Divider, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showNotification } from "../../actions/notificationAction";
import useWindowDimensions from "../../hooks/window-dimentions";
import httpService from "../../services/httpService";
import storageService from "../../services/storageService";
import AnimatedGradientText from "./components/AnimatedGradientText";
import FeatureItem from "./components/FeatureItem";
import Menu from "./components/Menu";

const LandingPage = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [contactName, setContactName] = useState("");
  const [contactSurname, setContactSurname] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("intelligrade-refreshToken") ? localStorage.getItem("intelligrade-refreshToken") : storageService.getRefreshToken());
  const currentTheme = useSelector((state) => state.theme).theme;
  const { t } = useTranslation();

  const featureImages = {
    assignmentCheck: "odev_kontrol.png",
    quiz: "quiz_kontrol.png",
    testAssignment: "test_assignment.png"
  };
  const [selectedFeature, setSelectedFeature] = useState(featureImages.assignmentCheck);

  const dispatch = useDispatch();

  const sendMail = async () => {
    if (
      contactName === "" ||
      !contactSurname === "" ||
      contactEmail === "" ||
      contactMessage === ""
    ) {
      dispatch(
        showNotification({
          type: "warning",
          message: t("LANDING_PAGE.MENU.CONTACT_ERROR"),
        })
      );
      return;
    }

    setLoading(true);

    const data = {
      name: `${contactName} ${contactSurname}`,
      from: contactEmail,
      message: contactMessage,
    };

    httpService
      .post("Info/sendinfomail", data)
      .then((res) => {
        dispatch(
          showNotification({ type: "success", message: t("LANDING_PAGE.MENU.MESSAGE_SENDED") })
        );
        setContactName("");
        setContactEmail("");
        setContactMessage("");
        setContactSurname("");
      })
      .catch(() => {
        dispatch(
          showNotification({
            type: "error",
            message:
              t("LANDING_PAGE.MENU.SEND_MESSAGE_ERROR"),
          })
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={`${currentTheme === "dark" && "dark"} h-screen overflow-y-auto overflow-x-hidden font-mono`}>
      <Menu token={token} />
      <div className="px-5 z-10 lg:px-20 pt-10 mt-[4.5rem] w-screen bg-[#F9F9FF] dark:bg-dark overflow-auto pb-10 relative">
        <div className="absolute -top-40 -left-5 w-[60rem] h-[60rem] rounded-full bg-[#5162ce6b] blur-[300px]"></div>
        <div className="absolute -top-40 -right-5 w-[60rem] h-[60rem] rounded-full bg-[#8151ce69] blur-[300px]"></div>


        <Row className="mt-20" justify={"center"} id="home">
          <Col span={24}>
            <img
              src={require("../../assets/images/rocket.png")}
              alt=""
              className="absolute -top-20 w-24 md:w-48 2xl:w-64 -right-5"
            />
            <img
              src={require("../../assets/images/phone.png")}
              alt=""
              className="absolute top-48 md:top-24 w-24 md:w-48 2xl:w-64 -left-10"
            />
            <div className="flex justify-center">
              <img
                src={require("../../assets/images/landing/title1.png")}
                alt=""
                className="h-8 mb-2 ml-0 lg:ml-1"
              />
            </div>
            <Row justify={"center"}>
              <Col xs={24} md={20} lg={16} xl={14} xxl={12}>
                <h1 className="text-5xl lg:text-6xl 2xl:text-7xl text-center text-gray-900 dark:text-gray-200">
                  {t("LANDING_PAGE.TITLE1")} <AnimatedGradientText text={t("LANDING_PAGE.TITLE2")} /> {t("LANDING_PAGE.TITLE3")}
                </h1>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col xs={24} md={22} lg={20} xl={18} xxl={16}>
                <p className="text-sm lg:text-base 2xl:text-xl mt-5 text-secondary dark:text-gray-400 px-0 lg:px-0 text-center">
                  {t("LANDING_PAGE.SUB_TITLE")}
                </p>
              </Col>
            </Row>
            <div className="flex justify-center">
              <div className="flex space-x-4 mt-5 justify-center lg:justify-start items-center">
                <button
                  className="bg-gradient-to-r from-[#5161ce] to-[#BC51CE] px-6 py-3 cursor-pointer text-base rounded-full text-white"
                  onClick={() => navigate("/login?register=true")}
                >
                  {t("LANDING_PAGE.FREE_REGISTER")}
                </button>
                {/* <Button type="text" className="flex space-x-2 items-center">
                  <Icon
                    icon="icon-park-solid:play"
                    className="text-primary text-base"
                  />
                  <span className="text-base text-secondary dark:text-gray-400">
                    Tanıtımı İzle
                  </span>
                </Button> */}
              </div>
            </div>

            <div className="flex justify-center mt-14">
              <motion.div
                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
              >
                <img
                  src={require("../../assets/images/landing_image.png")}
                  alt=""
                  style={{ width: width < 972 ? width / 1.2 : width / 1.4 }}
                />
              </motion.div>
            </div>
          </Col>
        </Row>
        <motion.div
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
        >
          <Row className="mt-40" justify={"center"} align={"middle"}>
            <Col xs={8} lg={4} className="flex-col flex items-center">
              <div className="p-6 bg-white dark:bg-dark2 rounded-full shadow-2xl shadow-gray-300 dark:shadow-gray-800">
                <img
                  src={require("../../assets/images/landing/create_account.png")}
                  alt=""
                  className="w-12"
                />
              </div>
              <p className="text-center mt-5 text-gray-900 dark:text-gray-200 font-semibold">
                {t("LANDING_PAGE.STEPS.STEP1")}
              </p>
            </Col>
            <Col xs={4} lg={2}>
              <Divider className="bg-gray-300 dark:bg-dark2 h-0.5 border-none" />
            </Col>
            <Col xs={8} lg={4} className="flex-col flex items-center">
              <div className="p-6 bg-white dark:bg-dark2 rounded-full shadow-2xl shadow-gray-300 dark:shadow-gray-800">
                <img
                  src={require("../../assets/images/landing/join.png")}
                  alt=""
                  className="w-12"
                />
              </div>
              <p className="text-center mt-5 text-gray-900 dark:text-gray-200 font-semibold">
                {t("LANDING_PAGE.STEPS.STEP2")}
              </p>
            </Col>
            <Col xs={0} lg={2}>
              <Divider className="bg-gray-300 dark:bg-dark2 h-0.5 border-none" />
            </Col>
            <Col
              xs={8}
              lg={4}
              className="flex-col flex items-center mt-10 lg:mt-0"
            >
              <div className="w-24 h-24 flex justify-center items-center bg-white dark:bg-dark2 rounded-full shadow-2xl shadow-gray-300 dark:shadow-gray-800">
                <img
                  src={require("../../assets/images/landing/ai.png")}
                  alt=""
                  className="w-12"
                />
              </div>
              <p className="text-center mt-5 text-gray-900 dark:text-gray-200 font-semibold">
                {t("LANDING_PAGE.STEPS.STEP3")}
              </p>
            </Col>
            <Col xs={4} lg={2}>
              <Divider className="bg-gray-300 dark:bg-dark2 h-0.5 border-none" />
            </Col>
            <Col
              xs={8}
              lg={4}
              className="flex-col flex items-center mt-10 lg:mt-0"
            >
              <div className="p-6 bg-white dark:bg-dark2 rounded-full shadow-2xl shadow-gray-300 dark:shadow-gray-800">
                <img
                  src={require("../../assets/images/landing/complate.png")}
                  alt=""
                  className="w-12"
                />
              </div>
              <p className="text-center mt-5 text-gray-900 dark:text-gray-200 font-semibold">
                {t("LANDING_PAGE.STEPS.STEP4")}
              </p>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <div className="flex justify-center mt-60" id="features">
            <img
              src={require("../../assets/images/landing/title4.png")}
              alt=""
              className="h-8 mb-2"
            />
          </div>
          <h1 className="text-4xl mt-3 text-center text-default dark:text-gray-200 ">
            {t("LANDING_PAGE.FEATURES.TITLE")}
          </h1>
          <p className="text-center mt-3 text-secondary dark:text-gray-400">
            {t("LANDING_PAGE.FEATURES.SUB_TITLE")}
          </p>

          <Row className="relative mt-20 justify-center">
            <Col xxl={20} xs={24}>
              <Row className="justify-center lg:justify-between">
                <Col xs={24} sm={20} md={14} lg={10} xl={10} xxl={8}>
                  <Row onClick={() => setSelectedFeature(featureImages.assignmentCheck)} className={`px-4 py-6 rounded-2xl cursor-pointer ${selectedFeature === featureImages.assignmentCheck ? "bg-gray-200 dark:bg-dark2" : "hover:bg-gray-200 hover:dark:bg-dark2"} transition-all duration-300`}>
                    <Col span={4}>
                      <div className="w-16 h-16 flex justify-center items-center bg-[#BA90FF20] rounded-full">
                        <img
                          src={require("../../assets/images/landing/assignment_check.png")}
                          alt=""
                          className="w-8"
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <p className="text-2xl text-default dark:text-gray-200 mt-2 font-bold">
                        {t("LANDING_PAGE.FEATURES.FEATURE1_TITLE")}
                      </p>
                      <p className="text-secondary dark:text-gray-400 mt-3">
                        {t("LANDING_PAGE.FEATURES.FEATURE1_DESC")}
                      </p>
                    </Col>
                  </Row>

                  <Row onClick={() => setSelectedFeature(featureImages.quiz)} className={`px-4 py-6 ${selectedFeature === featureImages.quiz ? "bg-gray-200 dark:bg-dark2" : "hover:bg-gray-200 hover:dark:bg-dark2"} cursor-pointer transition-all duration-300 rounded-2xl mt-10`}>
                    <Col span={4}>
                      <div className="w-16 h-16 flex justify-center items-center bg-[#72DC6020] rounded-full">
                        <img
                          src={require("../../assets/images/landing/edit.png")}
                          alt=""
                          className="w-8"
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <p className="text-2xl  text-default dark:text-gray-200 mt-2 font-bold">
                        {t("LANDING_PAGE.FEATURES.FEATURE2_TITLE")}
                      </p>
                      <p className="text-secondary dark:text-gray-400 mt-3">
                        {t("LANDING_PAGE.FEATURES.FEATURE2_DESC")}
                      </p>
                    </Col>
                  </Row>

                  <Row onClick={() => setSelectedFeature(featureImages.testAssignment)} className={`px-4 py-6 ${selectedFeature === featureImages.testAssignment ? "bg-gray-200 dark:bg-dark2" : "hover:bg-gray-200 hover:dark:bg-dark2"} cursor-pointer transition-all duration-300 rounded-2xl mt-10`}>
                    <Col span={4}>
                      <div className="w-16 h-16 flex justify-center items-center bg-[#FF749F20] rounded-full">
                        <img
                          src={require("../../assets/images/landing/pdf_asistant.png")}
                          alt=""
                          className="w-8"
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <p className="text-2xl text-default dark:text-gray-200 mt-2 font-bold">
                        {t("LANDING_PAGE.FEATURES.FEATURE3_TITLE")}
                      </p>
                      <p className="text-secondary dark:text-gray-400 mt-3">
                        {t("LANDING_PAGE.FEATURES.FEATURE3_DESC")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  lg={12}
                  xl={12}
                  className="mt-10 lg:mt-0 flex justify-center lg:justify-start"
                >
                  <img
                    src={require(`../../assets/images/landing/${selectedFeature}`)}
                    alt=""
                    className="h-3/6"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          initial={{ x: 100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <div className="flex justify-center mt-20" id="product">
            <img
              src={require("../../assets/images/landing/title2.png")}
              alt=""
              className="h-8 mb-2"
            />
          </div>
          <h1 className="text-4xl text-center mt-5 text-default dark:text-gray-200">
            {t("LANDING_PAGE.PRODUCT.TITLE")}
          </h1>
          <Row
            className="mt-10 lg:space-x-10 space-y-5 lg:space-y-0"
            justify={"center"}
          >
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM1_DESC")
                }
                imgUrl={require("../../assets/images/landing/folder.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM1_TITLE")}
              />
            </Col>
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM2_DESC")
                }
                imgUrl={require("../../assets/images/landing/assignment.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM2_TITLE")}
              />
            </Col>
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM3_DESC")
                }
                imgUrl={require("../../assets/images/landing/quiz.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM3_TITLE")}
              />
            </Col>
          </Row>

          <Row
            className="mt-10 lg:space-x-10 space-y-5 lg:space-y-0"
            justify={"center"}
          >
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM4_DESC")
                }
                imgUrl={require("../../assets/images/landing/objection.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM4_TITLE")}
              />
            </Col>
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM5_DESC")
                }
                imgUrl={require("../../assets/images/landing/test.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM5_TITLE")}
              />
            </Col>
            <Col xs={22} sm={18} md={14} lg={7} xxl={6}>
              <FeatureItem
                description={
                  t("LANDING_PAGE.PRODUCT.ITEM6_DESC")
                }
                imgUrl={require("../../assets/images/landing/pdf.png")}
                title={t("LANDING_PAGE.PRODUCT.ITEM6_TITLE")}
              />
            </Col>
          </Row>
        </motion.div>


        <motion.div
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
        >
          <Row
            align={"middle"}
            className="justify-center lg:justify-start mt-60"
          >
            <Col
              xs={24}
              sm={20}
              md={18}
              lg={12}
              className="flex justify-center lg:justify-start"
            >
              <img
                src={require("../../assets/images/landing/phone.png")}
                alt=""
                className="w-10/12"
              />
            </Col>
            <Col xs={24} sm={20} md={16} lg={12}>
              <div className="flex justify-center lg:justify-start">
                <img
                  src={require("../../assets/images/landing/title3.png")}
                  alt=""
                  className="h-8"
                />
              </div>
              <h1 className="text-4xl mt-5 text-center lg:text-start text-default dark:text-gray-200">
                {t("LANDING_PAGE.MOBILE.TITLE")}
              </h1>
              <p className="text-secondary dark:text-gray-400 mt-3 text-xl text-center lg:text-start">
                {t("LANDING_PAGE.MOBILE.SUB_TITLE")}
              </p>
              <div className="flex space-x-4 mt-10 justify-center lg:justify-start">
                <img
                  src={require("../../assets/images/landing/play_store.png")}
                  alt=""
                  className="cursor-pointer hover:opacity-80 transition-all duration-300"
                />
                <img
                  src={require("../../assets/images/landing/app_store.png")}
                  alt=""
                  className="cursor-pointer hover:opacity-80 transition-all duration-300"
                />
              </div>
            </Col>
          </Row>
        </motion.div>

        <motion.div
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          id="contact"
        >
          <h1 className="text-4xl mt-60 text-center text-default dark:text-gray-200">
            İletişimde Kalalım
          </h1>
          <p className="text-center mt-3 text-secondary dark:text-gray-400">
            Bizimle iletişime geçmek için aşağıdaki formu doldurabilirsiniz.
          </p>
          <Row justify={"center"}>
            <Col xxl={12} xl={16} lg={20} xs={24}>
              <div className="flex space-x-4 justify-center mt-10">
                <Input
                  size="large"
                  placeholder={t("LANDING_PAGE.CONTACT.NAME")}
                  onChange={(e) => setContactName(e.target.value)}
                  value={contactName}
                />
                <Input
                  size="large"
                  placeholder={t("LANDING_PAGE.CONTACT.SURNAME")}
                  onChange={(e) => setContactSurname(e.target.value)}
                  value={contactSurname}
                />
              </div>
              <Input
                size="large"
                placeholder={t("LANDING_PAGE.CONTACT.EMAIL")}
                className="mt-3"
                onChange={(e) => setContactEmail(e.target.value)}
                value={contactEmail}
              />
              <TextArea
                rows={10}
                placeholder={t("LANDING_PAGE.CONTACT.MESSAGE")}
                className="mt-3"
                size="large"
                onChange={(e) => setContactMessage(e.target.value)}
                value={contactMessage}
              />
              <Button
                loading={loading}
                block
                type="primary"
                size="large"
                className="mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  sendMail();
                }}
              >
                {t("LANDING_PAGE.CONTACT.SEND")}
              </Button>
            </Col>
          </Row>
        </motion.div>

        <Divider className="bg-gray-200 dark:bg-dark2 h-0.5 border-none mt-60" />

        <Row justify={"space-between"} className="mt-10">
          <Col xs={10} lg={4}>
            {/* <div className="flex space-x-2 items-center">
              <img
                src={require("../../assets/images/logo-color.png")}
                alt=""
                className="w-8 rounded-md"
              />
              <span className="text-base text-dark1 dark:text-gray-200">
                INTELLIGRADE
              </span>
            </div> */}
            <img
              src={require(`../../assets/images/${currentTheme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)}
              alt=""
              className="w-32 rounded-md"
            />
            <div className="flex space-x-2 items-center mt-5">
              <Icon
                icon="ic:outline-email"
                className="text-secondary dark:text-gray-400 text-xl"
              />
              <span className="text-secondary dark:text-gray-400">
                info@intelligrade.ai
              </span>
            </div>
            <div className="flex space-x-2 items-center mt-3">
              <Icon
                icon="ph:phone-bold"
                className="text-secondary dark:text-gray-400 text-xl"
              />
              <span className="text-secondary dark:text-gray-400">
                +90 551 165 6127
              </span>
            </div>
          </Col>
          <Col xs={10} lg={4}>
            <p className="text-default dark:text-gray-200 text-2xl">
              Page Links
            </p>
            <p className="text-light mt-3 cursor-pointer hover:text-secondary transition-all duration-300">
              Home
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              About Us
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              Pricing
            </p>
            <div className="flex space-x-2 mt-2 items-center cursor-pointer opacity-90 hover:opacity-100 transition-all duration-300">
              <p className="bg-gradient-to-r from-[#5161ce] to-[#CE51C1] inline-block text-transparent bg-clip-text">
                Features
              </p>
              <Icon icon="mdi:magic" className="text-[#CE51C1]" />
            </div>
          </Col>
          <Col xs={10} lg={4} className="mt-10 lg:mt-0">
            <p className="text-default dark:text-gray-200 text-2xl">
              Video Links
            </p>
            <p className="text-light mt-3 cursor-pointer hover:text-secondary transition-all duration-300">
              AI-Powered Assignment
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              AI-Powered Quiz
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              Objection System
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              File Manager
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              PDF Asistant
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              Assignment Check
            </p>
          </Col>
          <Col xs={10} lg={4} className="mt-10 lg:mt-0">
            <p className="text-default dark:text-gray-200 text-2xl">Policy</p>
            <p className="text-light mt-3 cursor-pointer hover:text-secondary transition-all duration-300">
              Term Of Use
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              Privacy Policy
            </p>
            <p className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              Cookie Policy
            </p>
            {/* <a href="intelligrade://(auth)/login" className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              deneme1
            </a>
            <a href="com.intelligrade.Intelligrade://(auth)/login" className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              deneme2
            </a>
            <a href="intelligrade://intelligrade.ai" className="text-light mt-2 cursor-pointer hover:text-secondary transition-all duration-300">
              deneme3
            </a> */}
          </Col>
          <Col xs={16} lg={5} className="mt-10 lg:mt-0">
            <p className="text-default dark:text-gray-200 text-2xl">
              Newsletter
            </p>
            <p className="text-secondary dark:text-gray-400 mt-3">
              Stay Up To Date
            </p>
            <div className="flex space-x-2">
              <Input
                placeholder="Enter your email..."
                className="rounded-2xl p-2 mt-2"
                suffix={
                  <Button
                    shape="circle"
                    type="primary"
                    className="flex justify-center items-center"
                  >
                    <Icon icon="fluent:send-28-filled" />
                  </Button>
                }
              />
            </div>
          </Col>
        </Row>
        <div className="flex justify-center space-x-2 items-center mt-20 text-secondary dark:text-gray-400">
          <Icon icon="ic:outline-copyright" className="text-xl" />
          <p>Copyright 2023 IntelliGrade. All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
