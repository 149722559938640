import { Avatar, Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../actions/notificationAction';
import { utcToLocaleLangFormat } from '../../../helpers/helperFunctions';
import httpService from '../../../services/httpService';
import storageService from '../../../services/storageService';


const NotificationInviteItem = ({ notification }) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const acceptInvite = () => {
        setLoading(true);

        httpService.get(`invite/acceptinvite/${notification.id}`, storageService.getToken()).then((res) => {
            dispatch(showNotification({ type: "success", message: "Davet başarıyla kabul edildi", description: "" }));
            window.location.reload();
        }).catch(e => {
            console.log(e);
            dispatch(showNotification({ type: "error", message: "Davet kabul edilemedi", desctiption: "Davet kabul edilirken bir hata oluştu, lütfen tekrar deneyiniz" }));

        }).finally(() => setLoading(false));
    };

    const rejectInvite = () => {
        setLoading(true);

        httpService.get(`invite/rejectinvite/${notification.id}`).then((res) => {
            dispatch(showNotification({ type: "success", message: "Davet başarıyla reddedildi", description: "" }));
            window.location.reload();
        }).catch(e => {
            console.log(e);
            dispatch(showNotification({ type: "error", message: "Davet reddedilemedi", desctiption: "Davet reddedilirken bir hata oluştu, lütfen tekrar deneyiniz" }));

        }).finally(() => setLoading(false));
    };

    return (
        <div className='hover:bg-gray-100 dark:hover:bg-dark2 cursor-pointer rounded-md transition-all duration-300 p-2'>
            <Row align={'top'}>
                <Col span={3}>
                    {notification.createdProfilePicture && notification.createdProfilePicture !== ""
                        ?
                        <Avatar size={'large'} src={notification.createdProfilePicture} />
                        :
                        <Avatar size={'large'} className='bg-[#5161ce]'><span>{notification.createdUserName[0]}</span></Avatar>
                    }
                </Col>
                <Col span={20}>
                    <div>
                        <p className='text-base'>{notification.createdUserName} <span className='text-primary text-sm'>davet etti</span> {notification.contentTitle.length > 15 ? `${notification.contentTitle.slice(0, 15)}...` : notification.contentTitle}</p>
                        <div className='flex space-x-2 items-center mt-1'>
                            <p className='text-xs text-gray-400'>{utcToLocaleLangFormat(notification.createdAt)}</p>
                            {/* <Avatar size={4} className='bg-gray-300' />
                            <p className='text-xs text-gray-400'>{notification.contentClassName}</p> */}
                        </div>
                    </div>
                </Col>
                {
                    !notification.isSeen && (
                        <Col span={1}>
                            <Avatar size={6} className='bg-[#5161ce]' />
                        </Col>
                    )
                }
            </Row>
            <Row className='space-x-2 mt-2'>
                <Col offset={3}>
                    <Button danger size='small' onClick={rejectInvite}>Reddet</Button>
                </Col>
                <Col>
                    <Button type='primary' size='small' loading={loading} onClick={acceptInvite}>Kabul Et</Button>
                </Col>
            </Row>
        </div>
    );
};

export default NotificationInviteItem;