import { Icon } from "@iconify/react";
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeTheme } from "../../../actions/themeAction";
import storageService from "../../../services/storageService";

const Menu = ({ token }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const theme = useSelector((state) => state.theme).theme;

  const handleChangeTheme = () => {
    dispatch(changeTheme(theme === "dark" ? "light" : "dark"));
    storageService.setCurrentTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div className="h-[4.5rem] bg-[#F9F9FF] dark:bg-dark absolute top-0 left-0 z-50 w-screen overflow-clip">
      <div className="absolute -top-40 -left-5 w-[60rem] h-[60rem] rounded-full bg-[#5162ce6b] blur-[300px]"></div>
      <div className="absolute -top-40 -right-5 w-[60rem] h-[60rem] rounded-full bg-[#8151ce69] blur-[300px]"></div>
      <div className="absolute w-full flex justify-between items-center h-full px-10">
        <div className="flex space-x-4 items-center">
          {/* <div className="flex space-x-2 items-center mr-10">
            <img
              src={require("../../../assets/images/logo-color.png")}
              alt=""
              className="w-9 rounded-md"
            />
            <p className="text-xl text-dark1 dark:text-gray-200 font-bold">
              INTELLIGRADE
            </p>
          </div> */}
          <img
            src={require(`../../../assets/images/${theme === "dark" ? 'logo-dark.png' : 'Logo.png'}`)}
            alt=""
            className="w-36 rounded-md"
          />
          <div className="md:flex space-x-4 items-center hidden">
            <a
              onClick={() => {
                const element = document.getElementById("home");
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
              className="text-secondary dark:text-gray-300 cursor-pointer hover:text-primary dark:hover:text-primary opacity-70 hover:opacity-100 transition-all duration-300"
            >
              {t("LANDING_PAGE.MENU.HOME")}
            </a>
            <div className="flex space-x-2 items-center cursor-pointer opacity-90 hover:opacity-100 transition-all duration-300">
              <a
                onClick={() => {
                  const element = document.getElementById("features");
                  element?.scrollIntoView({
                    behavior: 'smooth'
                  });
                }}
                className="bg-gradient-to-r from-[#5161ce] to-[#CE51C1] inline-block text-transparent bg-clip-text"
              >
                {t("LANDING_PAGE.MENU.FEATURES")}
              </a>
              <Icon icon="mdi:magic" className="text-[#CE51C1]" />
            </div>

            <a
              onClick={() => {
                const element = document.getElementById("product");
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
              className="text-secondary dark:text-gray-300 cursor-pointer hover:text-primary dark:hover:text-primary opacity-70 hover:opacity-100 transition-all duration-300"
            >
              {t("LANDING_PAGE.MENU.PRODUCT")}
            </a>
            {/* <a
              href="#pricing"
              className="text-secondary dark:text-gray-300 cursor-pointer hover:text-primary dark:hover:text-primary opacity-70 hover:opacity-100 transition-all duration-300"
            >
              Pricing
            </a> */}
            <a
              onClick={() => {
                const element = document.getElementById("contact");
                element?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
              className="text-secondary dark:text-gray-300 cursor-pointer hover:text-primary dark:hover:text-primary opacity-70 hover:opacity-100 transition-all duration-300"
            >
              {t("LANDING_PAGE.MENU.CONTACT")}
            </a>
          </div>
        </div>
        <div className="md:flex space-x-6 items-center hidden">
          <div
            className="p-2 mt-1 flex justify-center items-center rounded-full cursor-pointer hover:bg-[#5161ce20] transition-all duration-300"
            onClick={handleChangeTheme}
          >
            {theme === "light" ? (
              <Icon
                icon="iconamoon:mode-dark-light"
                className="text-gray-700"
              />
            ) : (
              <Icon icon="entypo:light-up" className="text-gray-400" />
            )}
          </div>
          {
            token
              ?
              <div className="flex space-x-2 items-center cursor-pointer text-primary opacity-80 hover:opacity-100" onClick={() => navigate("/classes")}>
                <p>
                  Sınıflarım
                </p>
                <Icon icon="mingcute:right-fill" />
              </div>
              :
              <div className="flex items-center space-x-6">
                <a
                  className="text-primary dark:text-gray-200 cursor-pointer opacity-90 hover:opacity-100 transition-all duration-300"
                  onClick={() => navigate("/login?register=false")}
                >
                  {t("LANDING_PAGE.MENU.LOGIN")}
                </a>
                <button
                  className="bg-[#5161ce] rounded-full px-6 py-3 cursor-pointer transition-all duration-300 hover:bg-[#4251b1]"
                  onClick={() => navigate("/login?register=true")}
                >
                  <p className="text-white text-base">{t("LANDING_PAGE.MENU.REGISTER")}</p>
                </button>
              </div>

          }
        </div>
        <div className="block md:hidden">
          <Button type="text" shape="circle" onClick={() => setShowMenu(true)}>
            <Icon icon="ic:round-menu" className="text-2xl" />
          </Button>
        </div>
        <div
          className={`absolute z-50 -top-96 left-0 w-full h-72 flex justify-between flex-row-reverse text-white py-4 px-8 bg-[#2f3d97] ${showMenu ? "translate-y-96" : "-translate-y-96"
            } transition-transform duration-300`}
        >
          <div className="flex justify-end">
            <Button
              type="text"
              shape="circle"
              onClick={() => setShowMenu(false)}
            >
              <Icon icon="ic:round-close" className="text-2xl text-white" />
            </Button>
          </div>
          <div className="mt-2">
            <a
              href="home"
              onClick={() => setShowMenu(false)}
              className="cursor-pointer text-white mt-2 block"
            >
              {t("LANDING_PAGE.MENU.HOME")}
            </a>
            <a
              href="product"
              onClick={() => setShowMenu(false)}
              className="cursor-pointer text-white mt-2 block"
            >
              {t("LANDING_PAGE.MENU.PRODUCT")}
            </a>
            {/* <a
              href="pricing"
              onClick={() => setShowMenu(false)}
              className="cursor-pointer text-white mt-2 block"
            >
              Pricing
            </a> */}
            <a
              href="contact"
              onClick={() => setShowMenu(false)}
              className="cursor-pointer text-white mt-2 block"
            >
              {t("LANDING_PAGE.MENU.CONTACT")}
            </a>
            <div className="flex mt-2 space-x-2 items-center cursor-pointer opacity-90 hover:opacity-100 transition-all duration-300">
              <a
                href="features"
                onClick={() => setShowMenu(false)}
                className="bg-gradient-to-r from-[#65c4bc] to-[#CE51C1] inline-block text-transparent bg-clip-text"
              >
                {t("LANDING_PAGE.MENU.FEATURES")}
              </a>
              <Icon icon="mdi:magic" className="text-[#CE51C1]" />
            </div>
            <div className="mt-10 flex space-x-4 items-center">
              <a
                href="/login?register=false"
                onClick={() => setShowMenu(false)}
                className="cursor-pointer text-white"
              >
                {t("LANDING_PAGE.MENU.LOGIN")}
              </a>
              <button
                className="bg-white rounded-full px-4 py-2 cursor-pointer transition-all duration-300 hover:bg-gray-300"
                onClick={() => {
                  navigate("/login?register=true");
                  setShowMenu(false);
                }}
              >
                <p className="text-primary text-base">{t("LANDING_PAGE.MENU.REGISTER")}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`bg-black absolute bottom-0 left-0 w-screen h-screen z-10 opacity-30 ${showMenu ? "block" : "hidden"
          }`}
      ></div>
    </div>
  );
};

export default Menu;
