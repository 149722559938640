import { Icon } from "@iconify/react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Popover,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showNotification } from "../actions/notificationAction";
import { changeTheme } from "../actions/themeAction";
import useWindowDimensions from "../hooks/window-dimentions";
import CreateClass from "../pages/Classes/components/CreateClass";
import JoinClass from "../pages/Classes/components/JoinClass";
import authorizationService from "../services/authorizationService";
import httpService from "../services/httpService";
import storageService from "../services/storageService";
import { getNameSurnameByToken, getProfilePhotoUrlByToken } from "../services/userService";
import NotificationContent from "./NotificationContent/NotificationContent";

const lngItems = [
  {
    label: (
      <img
        src={require("../assets/images/turkey.png")}
        style={{ width: 24 }}
        alt=""
      />
    ),
    key: "tr",
  },
  {
    label: (
      <img
        src={require("../assets/images/united-states.png")}
        style={{ width: 24 }}
        alt=""
      />
    ),
    key: "en",
  },
  // ,{
  //   label: (
  //     <img
  //       src={require("../../assets/images/china.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "zh",
  // },
  // {
  //   label: (
  //     <img
  //       src={require("../../assets/images/spain.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "es",
  // },
  // {
  //   label: (
  //     <img
  //       src={require("../../assets/images/france.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "fr",
  // },
  // {
  //   label: (
  //     <img
  //       src={require("../../assets/images/russia.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "ru",
  // },
  // {
  //   label: (
  //     <img
  //       src={require("../../assets/images/italy.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "it",
  // },
  // {
  //   label: (
  //     <img
  //       src={require("../../assets/images/germany.png")}
  //       style={{ width: 24 }}
  //       alt=""
  //     />
  //   ),
  //   key: "de",
  // },
];

const languageFlagsSources = {
  tr: "turkey",
  en: "united-states",
  // zh: "china",
  // es: "spain",
  // fr: "france",
  // ru: "russia",
  // it: "italy",
  // de: "germany",
};

const ProTopBar = ({
  collapsed,
  setCollapsed,
  openDrawer,
  setOpenDrawer,
  theme,
}) => {
  const { t, i18n } = useTranslation();
  const [classCreateOpen, setClassCreateOpen] = useState(false);
  const [classJoinOpen, setClassJoinOpen] = useState(false);
  const [classCode, setClassCode] = useState("");
  const [className, setClassName] = useState("");
  const [topTitle, setTopTitle] = useState("");
  const [notificationFlowLoading, setNotificationFlowLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isNotificationFlowFinished, setIsNotificationFlowFinished] = useState(false);
  const { classId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPage = useSelector((state) => state.page);
  const [lng, setLng] = useState(storageService.getLanguage());
  const { width } = useWindowDimensions();
  const [bellIcon, setBellIcon] = useState("solar:bell-linear");
  const [notifications, setNotifications] = useState([]);
  const profileImage = getProfilePhotoUrlByToken();
  const notificationTop = 10;

  useEffect(() => {
    const notificationContainer = document.getElementById("notificationContainer");
    console.log(notificationContainer);

    const handleScroll = (event) => {
      if (
        notificationContainer.scrollTop + notificationContainer.clientHeight >=
        notificationContainer.scrollHeight - 15 &&
        !isNotificationFlowFinished
      ) {
        getFlow();
        notificationContainer?.removeEventListener("scroll", handleScroll);
      }
    };

    notificationContainer?.removeEventListener("scroll", handleScroll);
    notificationContainer?.addEventListener("scroll", handleScroll);

    return () => {
      notificationContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [offset, bellIcon]);

  useEffect(() => {
    getFlow(true);
  }, []);

  const getFlow = (refresh) => {
    if (refresh) {
      setIsNotificationFlowFinished(false);
      setNotifications([]);
      setNotificationFlowLoading(true);
      httpService
        .get(
          `notification/getNotifications?offset=0&top=${notificationTop}`,
          storageService.getToken()
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            res.data.data && setNotifications((prev) => [...res.data.data]);
            if (res.data.data.length < notificationTop) setIsNotificationFlowFinished(true);
          }
        })
        .catch((e) => {
          dispatch(
            showNotification({
              type: "error",
              message: t("NOTIFICATION.CLASS.FLOW_ERROR"),
            })
          );
        })
        .finally(() => {
          setNotificationFlowLoading(false);
          setOffset(notificationTop);
        });
    } else if (!isNotificationFlowFinished) {
      setNotificationFlowLoading(true);
      httpService
        .get(
          `notification/getNotifications?offset=${offset}&top=${notificationTop}`,
          storageService.getToken()
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            res.data.data && setNotifications((prev) => [...prev, ...res.data.data]);
            if (res.data.data.length < notificationTop) setIsNotificationFlowFinished(true);
          }
        })
        .catch((e) => {
          dispatch(
            showNotification({
              type: "error",
              message: t("NOTIFICATION.CLASS.FLOW_ERROR"),
            })
          );
        })
        .finally(() => {
          setNotificationFlowLoading(false);
          setOffset(offset + notificationTop);
        });
    }
  };


  const handleChangeTheme = () => {
    storageService.setCurrentTheme(theme === "light" ? "dark" : "light");
    dispatch(changeTheme(theme === "light" ? "dark" : "light"));
  };

  const profileItems = [
    {
      label: t("LAYOUT.NAVBAR.MY_PROFILE"),
      key: "0",
      icon: <Icon icon="iconamoon:profile" />,
    },
    {
      label: t("LAYOUT.NAVBAR.LOGOUT"),
      key: "2",
      icon: <Icon icon="tabler:logout" />,
    },
  ];

  const handleLngChange = (info) => {
    setLng(info.key);
    storageService.changeLanguage(info.key);
    i18n.changeLanguage(info.key);
  };

  useEffect(() => {
    if (selectedPage === "1") {
      setClassCode("");
      setClassName("");
    }
  }, [selectedPage]);

  const addItems = [
    {
      label: t("LAYOUT.NAVBAR.CREATE_CLASS"),
      key: "0",
      icon: <Icon icon="uil:create-dashboard" />,
    },

    {
      label: t("LAYOUT.NAVBAR.JOIN_CLASS"),
      key: "1",
      icon: <Icon icon="fluent:arrow-join-20-regular" />,
    },
  ];

  const getClassCodeAndClassName = () => {
    httpService
      .get(`lesson/getbyid/${classId}`, storageService.getToken())
      .then((res) => {
        if (res !== undefined)
          if (res.data.statusCode === 200) {
            setClassCode(res.data.data.classCode);
            setClassName(res.data.data.className);
          }
      })
      .catch((e) => { });
  };

  const handleProfileClick = (info) => {
    if (info.key === "2") {
      // logout
      authorizationService.logOut(dispatch, t);
    } else if (info.key === "0") {
      // go profile page
      navigate("/profile");
    }
  };

  const handleClassDropdownClick = (info) => {
    // key 0 create class, key 1 join class
    if (info.key === "0") {
      setClassCreateOpen(true);
    } else if (info.key === "1") {
      setClassJoinOpen(true);
    }
  };

  const copyClassCode = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(classCode);
    // Alert the copied text
    dispatch(
      showNotification({
        type: "success",
        message: t("NOTIFICATION.NAVBAR.COPY"),
      })
    );
  };

  const handleMenuButtonClick = () => {
    if (width <= 768) {
      setOpenDrawer((openDrawer) => !openDrawer);
    } else {
      setCollapsed((collapsed) => !collapsed);
    }
  };

  useEffect(() => {
    if (window.location.pathname !== "/login")
      if (authorizationService.authControl(dispatch, navigate, t)) {
        classId && getClassCodeAndClassName();
      }

    if (window.location.pathname.startsWith("/classes"))
      setTopTitle("Akış");
    else if (window.location.pathname.startsWith("/profile"))
      setTopTitle("Profil");
    else if (window.location.pathname.startsWith("/assignment"))
      setTopTitle("Ödev Detayı");
    else if (window.location.pathname.startsWith("/quizinspect"))
      setTopTitle("Quiz Detayı");
    else if (window.location.pathname.startsWith("/students"))
      setTopTitle("Sınıf Üyeleri");
    else if (window.location.pathname.startsWith("/class-files"))
      setTopTitle("Sınıf Dosyaları");
    else if (window.location.pathname.startsWith("/testassignment"))
      setTopTitle("Ödev Test Et");
    else if (window.location.pathname.startsWith("/notelist"))
      setTopTitle("Ödev Not Listesi");
    else if (window.location.pathname.startsWith("/quiznotelist"))
      setTopTitle("Quiz Not Listesi");
    else if (window.location.pathname.startsWith("/quizcreate"))
      setTopTitle("Quiz Oluştur");
    else if (window.location.pathname.startsWith("/objection"))
      setTopTitle("İtirazlar");
    else
      setTopTitle("Akış");


  }, [classId, window.location.pathname]);

  return (
    <div
      style={{
        height: 64,
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e3e3e3",
        borderColor: theme === "dark" ? "black" : "#e3e3e3",
        paddingLeft:
          width > 1200 ? 30 : collapsed && width > 768 ? width / 20 : 10,
        paddingRight: 30,
      }}
      className="bg-white dark:bg-dark border-dark"
    >
      <Row justify={"space-between"} align={"middle"} style={{ width: "100%" }}>
        <Col>
          <div className="flex items-center">
            <Button type="text" shape="circle" onClick={handleMenuButtonClick}>
              <Icon
                icon="material-symbols:menu-rounded"
                style={{ fontSize: "1.2rem", marginTop: 2 }}
                className="text-gray-900 dark:text-gray-100"
              />
            </Button>
            <div>
              <p className="text-2xl text-secondary dark:text-gray-200 ml-5">
                {topTitle}
              </p>
              {width >= 768 && (
                <p className="text-sm text-light dark:text-gray-300 ml-5">
                  {className.length > 20
                    ? `${className.split(0, 17)}...`
                    : className}
                </p>
              )}
            </div>
          </div>
        </Col>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Space size={width < 500 ? 2 : 10}>
              <Dropdown
                menu={{
                  items: addItems,
                  onClick: handleClassDropdownClick,
                }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button type="text" shape="circle">
                  <Icon
                    icon="ic:round-add"
                    style={{
                      fontSize: "1.5rem",
                    }}
                    className="text-secondary dark:text-gray-200"
                  />
                </Button>
              </Dropdown>
              {classCode !== "" && width > 500 && (
                <Button type="text" onClick={copyClassCode}>
                  <p className="dark:text-gray-200 text-gray-800">
                    Sınıf Kodu: {classCode}
                  </p>
                </Button>
              )}

              <Dropdown
                menu={{ items: lngItems, onClick: handleLngChange }}
                trigger={["click"]}
              >
                <Badge
                  count={
                    <img
                      src={require(`../assets/images/${languageFlagsSources[lng]}.png`)}
                      style={{
                        width: 12,
                        marginTop: "0.2rem",
                        marginRight: "0.2rem",
                      }}
                      alt=""
                    />
                  }
                >
                  <Button type="text" shape="circle">
                    <Icon
                      icon="mdi:language"
                      fontSize={20}
                      className="text-secondary dark:text-gray-200"
                    />
                  </Button>
                </Badge>
              </Dropdown>
              <Button type="text" shape="circle" onClick={handleChangeTheme}>
                {theme === "light" ? (
                  <Icon
                    icon="iconamoon:mode-dark-light"
                    style={{
                      fontSize: "1.5rem",
                      color: "var(--text-secondary)",
                    }}
                  />
                ) : (
                  <Icon
                    icon="entypo:light-up"
                    style={{ fontSize: "1.5rem" }}
                    className="text-gray-200"
                  />
                )}
              </Button>
              <Badge
                count={notifications.filter((item) => !item.isSeen).length}
              >
                <Popover
                  placement="bottomRight"
                  content={
                    <NotificationContent
                      notifications={notifications}
                      setNotifications={setNotifications}
                      notificationFlowLoading={notificationFlowLoading}
                      notificationTop={notificationTop}
                    />
                  }
                  trigger={["click"]}
                  onOpenChange={(visible) =>
                    setBellIcon(
                      visible ? "solar:bell-bold" : "solar:bell-linear"
                    )
                  }
                >
                  <Button type="text" shape="circle">
                    <Icon
                      icon={bellIcon}
                      style={{ fontSize: "1.5rem" }}
                      className="dark:text-gray-200 text-gray-700"
                    />
                  </Button>
                </Popover>
              </Badge>
              <Dropdown
                menu={{ items: profileItems, onClick: handleProfileClick }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <a>
                  <Avatar src={profileImage !== "" && profileImage}>
                    {
                      profileImage === "" &&
                      getNameSurnameByToken() &&
                      getNameSurnameByToken().charAt(0)
                    }
                  </Avatar>
                </a>
              </Dropdown>
            </Space>
          </div>
        </Col>
      </Row>

      <CreateClass
        classCreateOpen={classCreateOpen}
        setClassCreateOpen={setClassCreateOpen}
      />
      <JoinClass
        classJoinOpen={classJoinOpen}
        setClassJoinOpen={setClassJoinOpen}
      />
    </div>
  );
};

export default ProTopBar;
