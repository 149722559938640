import { showNotification } from "../actions/notificationAction";
import { setCurrentUser } from "../actions/userAction";
import httpService from "./httpService";
import storageService from "./storageService";

class AuthorizationService {
  authControl = (dispatch, navigate, t) => {
    if (!storageService.getToken() && !storageService.getRefreshToken() && !localStorage.getItem("intelligrade-refreshToken")) {
      try {
        dispatch(
          showNotification({
            type: "error",
            message: t(
              "NOTIFICATION.AUTHORIZATION_SERVICE.AUTHORIZATION_ERROR_MESSAGE"
            ),
            description: t(
              "NOTIFICATION.AUTHORIZATION_SERVICE.AUTHORIZATION_ERROR_DESCRIPTION"
            ),
          })
        );
      } catch (e) { }
      navigate("/login?register=false");
      return false;
    }
    return true;
  };

  logOut = (dispatch, t) => {
    storageService.removeToken();
    storageService.removeRefreshToken();
    localStorage.removeItem("intelligrade-refreshToken");
    window.location.href = "/";
  };

  logIn = (dispatch, navigate, t, loginForm, rememberMe) => {
    return httpService
      .post("auth/login", loginForm)
      .then((res) => {
        if (res.data.statusCode === 200) {
          // save token
          storageService.addToken(res.data.data.accessToken);
          storageService.addRefreshToken(res.data.data.refreshToken);
          if (rememberMe) {
            localStorage.setItem("intelligrade-refreshToken", res.data.data.refreshToken);
          } else {
            localStorage.removeItem("intelligrade-refreshToken");
          }

          httpService
            .get("user/getuser", res.data.data.accessToken)
            .then((res) => {
              dispatch(setCurrentUser(res.data.data));
            })
            .catch((e) => console.log(e));

          navigate("/classes");
        }
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.data.statusCode !== 500) {
            if (e.response.data.message === "userNotFound")
              dispatch(
                showNotification({
                  type: "error",
                  message: t("NOTIFICATION.LOGIN.USER_NOT_FOUND_ERROR.MESSAGE"),
                  description: t(
                    "NOTIFICATION.LOGIN.USER_NOT_FOUND_ERROR.CONTENT"
                  ),
                })
              );
            else if (e.response.data.message === "emailNotConfirmed")
              dispatch(
                showNotification({
                  type: "error",
                  message: "Hesap Onaylanmamış",
                  description:
                    "Lütfen e-postanıza gelen onaylama linkine tıklayarak hesabınızı onaylayın",
                })
              );
            else if (e.response.data.message === "passwordNotMatch")
              dispatch(
                showNotification({
                  type: "error",
                  message: "Şifre Yanlış",
                  description: "Lütfen şifrenizi kontrol edin",
                })
              );
          } else {
            dispatch(
              showNotification({
                type: "error",
                message: "Sunucu Hatası",
                description: "Lütfen daha sonra tekrar deneyin",
              })
            );
          }
        } else {
          dispatch(
            showNotification({
              type: "error",
              message: "Sunucu Hatası",
              description: "Lütfen daha sonra tekrar deneyin",
            })
          );
        }
      });
  };

  logInWithGoogle = (dispatch, navigate, t, googleToken) => {
    return httpService
      .post("auth/loginwithgoogle", googleToken)
      .then((res) => {
        if (res.data.statusCode === 200 || res.data.statusCode === 201) {
          // save token
          storageService.addToken(res.data.data.accessToken);
          storageService.addRefreshToken(res.data.data.refreshToken);
          localStorage.setItem("intelligrade-refreshToken", res.data.data.refreshToken);

          httpService
            .get("user/getuser", res.data.data.accessToken)
            .then((res) => {
              dispatch(setCurrentUser(res.data.data));
            })
            .catch((e) => console.log(e));

          navigate("/classes");
        }
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.data.statusCode !== 500) {
            if (e.response.data.message === "userNotFound")
              dispatch(
                showNotification({
                  type: "error",
                  message: t("NOTIFICATION.LOGIN.USER_NOT_FOUND_ERROR.MESSAGE"),
                  description: t(
                    "NOTIFICATION.LOGIN.USER_NOT_FOUND_ERROR.CONTENT"
                  ),
                })
              );
            else if (e.response.data.message === "emailNotConfirmed")
              dispatch(
                showNotification({
                  type: "error",
                  message: "Hesap Onaylanmamış",
                  description:
                    "Lütfen e-postanıza gelen onaylama linkine tıklayarak hesabınızı onaylayın",
                })
              );
            else if (e.response.data.message === "passwordNotMatch")
              dispatch(
                showNotification({
                  type: "error",
                  message: "Şifre Yanlış",
                  description: "Lütfen şifrenizi kontrol edin",
                })
              );
          } else {
            dispatch(
              showNotification({
                type: "error",
                message: "Sunucu Hatası",
                description: "Lütfen daha sonra tekrar deneyin",
              })
            );
          }
        } else {
          dispatch(
            showNotification({
              type: "error",
              message: "Sunucu Hatası",
              description: "Lütfen daha sonra tekrar deneyin",
            })
          );
        }
      });
  };

  signUp = (dispatch, t, signUpForm) => {
    return httpService
      .post("auth/register", signUpForm)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch(
            showNotification({
              type: "success",
              message:
                "Kayıt başarılı. Lütfen e-posta adresinize gönderilen onay linkine tıklayarak hesabınızı onaylayın.",
            })
          );
        }
      })
      .catch((e) => {
        if (e.response.status !== 500) {
          if (e.response.data.message === "emailAlreadyExists")
            dispatch(
              showNotification({
                type: "error",
                message: "Hata",
                description: t("NOTIFICATION.LOGIN.ALREADY_HAVE_USER"),
              })
            );
        } else
          dispatch(
            showNotification({
              type: "error",
              message: t("NOTIFICATION.LOGIN.ERROR"),
              description: t("NOTIFICATION.LOGIN.SYSTEM_ERROR"),
            })
          );
        throw e;
      });
  };
}

var authorizationService = new AuthorizationService();

export default authorizationService;
