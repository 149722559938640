import React, { useEffect, useState } from 'react'
import ProSideBar from '../components/SideBar'
import { Col, Drawer, Row } from 'antd'
import ProTopBar from '../components/TopBar'
import { Outlet } from 'react-router-dom'
import useWindowDimensions from '../hooks/window-dimentions'
import storageService from '../services/storageService'

const ProLayout = ({ theme }) => {

  const [collapsed, setCollapsed] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);


  return (
    <Row style={{ backgroundColor: theme === "light" ? "rgba(249, 249, 255, 0.5)" : "#25293c" }} className='overflow-hidden h-screen transition-all duration-300 ease-in-out'>
      <Col xxl={collapsed ? 1 : 3} lg={collapsed ? 1 : 4} xs={0} style={{ transition: "all 0.2s ease", zIndex: 999 }}>
        <ProSideBar collapsed={collapsed} setCollapsed={setCollapsed} inDrawer={false} />
      </Col>
      <Col className={`flex-1 ${theme === "dark" && 'dark'}`} style={{ transition: "all 0.2s ease", zIndex: 10 }}>
        <ProTopBar collapsed={collapsed} setCollapsed={setCollapsed} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} theme={theme} />
        <div style={{ padding: 10, paddingLeft: 20 }} id="topContainer" className='overflow-y-auto h-[95vh]'>
          <Outlet />
        </div>
      </Col>

      <Drawer placement="left" onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <ProSideBar collapsed={collapsed} setCollapsed={setCollapsed} inDrawer={true} setOpenDrawer={setOpenDrawer} />
      </Drawer>
    </Row>
  )
}

export default ProLayout