import deDe from "antd/lib/locale/de_DE";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import frFR from "antd/lib/locale/fr_FR";
import itIT from "antd/lib/locale/it_IT";
import ruRU from "antd/lib/locale/ru_RU";
import trTR from "antd/lib/locale/tr_TR";
import zhCN from "antd/lib/locale/zh_CN";
import dayjs from "dayjs";
import moment from "moment";
import { GptLanguageType } from "../models/enums/GptLanguageType";

export const utcToLocaleLangFormat = (utcFormat, lang, hour = true, minute = true) => {
  const date = new Date(utcFormat);
  let offset = new Date().getTimezoneOffset();
  date.setMinutes(date.getMinutes() - offset);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: hour ? "numeric" : undefined,
    minute: minute ? "numeric" : undefined,
    timeZone: "UTC",
  };
  const formattedDate = date.toLocaleDateString(lang, options);
  return formattedDate.toString();
};

export const getAntdLocale = (lang) => {
  switch (lang) {
    case "de":
      return deDe;
    case "en":
      return enUS;
    case "es":
      return esES;
    case "fr":
      return frFR;
    case "it":
      return itIT;
    case "ru":
      return ruRU;
    case "tr":
      return trTR;
    case "zh":
      return zhCN;
    default:
      return enUS;
  }
};


export const getAILanguageType = (lang) => {
  if (lang === "tr") return GptLanguageType.Tr;
  else
    return GptLanguageType.En;
  // switch (lang) {
  //   case "de":
  //     return "de-DE";
  //   case "en":
  //     return "en-US";
  //   case "es":
  //     return "es-ES";
  //   case "fr":
  //     return "fr-FR";
  //   case "it":
  //     return "it-IT";
  //   case "ru":
  //     return "ru-RU";
  //   case "tr":
  //     return "tr-TR";
  //   case "zh":
  //     return "zh-CN";
  //   default:
  //     return "en-US";
  // }
};

export const sortByName = (a, b) => {
  if (a.name < b.name) return -1;

  if (a.name > b.name) return 1;

  return 0;
};

export const sortByDate = (a, b) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  return dateA - dateB;
};

export const groupFolder = (children) => {
  var groupedFolder = [];
  var extensions = [];

  children.forEach((child) => {
    if (child.isFolder) {
      groupedFolder.push(child);
    } else {
      const extension = child.name.split(".").pop();
      if (!extensions.includes(extension)) extensions.push(extension);
    }
  });

  extensions.forEach((extension) => {
    children.forEach((child) => {
      if (!child.isFolder) {
        const childExtension = child.name.split(".").pop();
        if (childExtension === extension) groupedFolder.push(child);
      }
    });
  });

  return groupedFolder;
};

export const getFileCount = (children) => {
  let fileCount = 0;

  children.forEach((child) => {
    if (!child.isFolder) {
      fileCount++;
    }

    if (child.children && child.children.length > 0) {
      fileCount += getFileCount(child.children);
    }
  });

  return fileCount;
};

export const searchFileOrFolder = (children, term) => {
  var searchedFolders = [];

  children.forEach((child) => {
    if (child.name.toLowerCase().includes(term.toLowerCase()))
      searchedFolders.push(child);
  });

  return searchedFolders;
};

export const convertGmtToLocal = (date) => {
  var offset = new Date().getTimezoneOffset();
  var localDate = moment.utc(date).add(-offset, "minutes");
  return localDate;
};

export const convertLocalToGmt = (date) => {
  var offset = new Date().getTimezoneOffset();
  var gmtDate = moment
    .utc(date.format("YYYY-MM-DDTHH:mm:ss[Z]"))
    .add(offset, "minutes");
  return gmtDate;
};

export const convertApiDateToAntdDate = (date) => {
  return dayjs(convertGmtToLocal(date).toISOString(), "YYYY-MM-DD HH:mm");
};

export const removeFileById = (fileId, folders) => {
  // "folders" state'i içinde dolaşarak dosyayı ara ve kaldır
  const findAndRemove = (folderList) => {
    for (let i = 0; i < folderList.length; i++) {
      const folder = folderList[i];
      if (folder.id === fileId) {
        // Dosyayı bulduk, bu nedenle kaldıralım
        folderList.splice(i, 1);
        return true; // Dosya bulundu ve kaldırıldı
      }

      // Eğer bu bir klasör ise ve çocukları varsa, çocuklarda da aynı işlemi yap
      if (folder.isFolder && folder.children && folder.children.length > 0) {
        const childRemoved = findAndRemove(folder.children);
        if (childRemoved) return true; // Eğer çocuklar içinde bulunduysa, döngüyü sonlandır
      }
    }
    return false; // Dosya bulunamadı
  };

  findAndRemove(folders.children);

  return folders;
};

export const addFileOrFolder = (folderId, folders, newFolderData) => {
  // "folders" state'i içinde dolaşarak dosyayı ara ve kaldır
  const findAndAdd = (folderList) => {
    for (let i = 0; i < folderList.length; i++) {
      const folder = folderList[i];
      if (folder.id === folderId) {
        // Dosyayı bulduk, bu nedenle kaldıralım
        folder.children.push(newFolderData);
        return true; // Dosya bulundu ve kaldırıldı
      }

      // Eğer bu bir klasör ise ve çocukları varsa, çocuklarda da aynı işlemi yap
      if (folder.isFolder && folder.children && folder.children.length > 0) {
        const childRemoved = findAndAdd(folder.children);
        if (childRemoved) return true; // Eğer çocuklar içinde bulunduysa, döngüyü sonlandır
      }
    }
    return false; // Dosya bulunamadı
  };

  findAndAdd(folders.children);

  return folders;
};

export const traverseData = (data, searchedId) => {
  // Verilerin bulunduğu diziyi kontrol edin
  if (Array.isArray(data.children)) {
    data.children.forEach((item) => {
      // İstenilen işlemi burada yapabilirsiniz, örneğin id'ye göre arama
      if (item.id === searchedId) {
        // console.log("Dosya bulundu!: ", item);
      }

      // Eğer iç içe başka bir veri varsa, bu işlemi tekrar çağırın
      if (item.children && item.children.length > 0) {
        traverseData(item);
      }
    });
  }
};

export const findFolderCount = (folder) => {
  let folderCount = 0;

  function traverseChildren(folder) {
    if (folder.isFolder && folder.children) {
      // Eğer klasörse ve alt düğümleri varsa
      for (const children of folder.children) {
        traverseChildren(children); // Alt düğümleri tara
      }
    } else if (!folder.isFolder) {
      // Eğer klasör değilse ve direkt bir dosyaysa
      folderCount++;
    }
  }

  traverseChildren(folder);
  return folderCount;
};

export const groupFoldersCount = (folder) => {
  let groups = []; // {type: string, count: number}

  const isContain = (type) => {
    let finded = false;

    for (let i = 0; i < groups.length; i++) {
      if (groups[i].type === type) {
        groups[i].count++;
        finded = true;
        break;
      }
    }

    if (!finded) {
      groups.push({ type: type, count: 1 });
    }
  };

  function traverseChildren(folder) {
    if (folder.isFolder && folder.children) {
      // Eğer klasörse ve alt düğümleri varsa
      for (const children of folder.children) {
        traverseChildren(children); // Alt düğümleri tara
      }
    } else if (!folder.isFolder) {
      // Eğer klasör değilse ve direkt bir dosyaysa
      const type = folder.name.split(".")[folder.name.split(".").length - 1];
      isContain(type);
    }
  }

  traverseChildren(folder);

  return groups;
};

export const getFileSizes = async (folder) => {
  let totalSize = 0;

  async function traverseChildren(folder) {
    if (folder.isFolder && folder.children) {
      // Eğer klasörse ve alt düğümleri varsa
      for (const children of folder.children) {
        traverseChildren(children); // Alt düğümleri tara
      }
    } else if (!folder.isFolder) {
      // Eğer klasör değilse ve direkt bir dosyaysa
      const blob = await (await fetch(folder.fileUrl)).blob();
      const file = new File([blob], "dosya");

      const mbSize = (file.size / (1024 * 1024)).toFixed(1);
      totalSize += parseFloat(mbSize);
      console.log(totalSize);
    }
  }

  await traverseChildren(folder);

  return totalSize;
};
