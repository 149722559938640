import {SET_CURRENT_USER, SET_IS_TEACHER} from "../actions/userAction";

const initialState = {
   id: "",
   email: "",
   name: "",
   surname: "",
   isTeacher: false
}

const userReducer = (state = initialState, action) => {
   switch(action.type){
      case SET_CURRENT_USER:
         return {
            ...state,
            id: action.payload.id,
            email: action.payload.email,
            name: action.payload.name,
            surname: action.payload.surname
         }

      case SET_IS_TEACHER:
         return {
            ...state,
            isTeacher: action.payload
         }

      default:
         return state;
   }
}

export default userReducer;